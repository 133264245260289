import React from 'react';
import TableCellSettingContext from 'src/components/context/TableCellSettingContext';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const TableCellSettingContextProvider = (props) => {
    const { children, showLink } = props;
    return (
        <TableCellSettingContext.Provider value={{ showLink }}>
            {children}
        </TableCellSettingContext.Provider>
    );
};

TableCellSettingContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    showLink: customPropTypes.showLinkInTable
};

TableCellSettingContextProvider.defaultProps = {
    showLink: 'in-app'
};

export default TableCellSettingContextProvider;
