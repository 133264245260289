import React, { PureComponent } from 'react';
import HighchartError from 'src/components/chartViews/highcharts/HighchartError';
import Highcharts from 'src/components/chartViews/highcharts/Highcharts';
import Highmaps from 'src/components/chartViews/highcharts/Highmaps';
import PropTypes from 'prop-types';
import withHighcharts from 'src/components/chartViews/highcharts/HighchartsComponent';
import { makeFormatNumber } from './configs/utils';

const highchartsChartViewConnector = (configCallback, type = 'Chart') => {
    const HighchartsEngine = type === 'Map' ? Highmaps : Highcharts;
    const HighchartComponent = withHighcharts(type, HighchartsEngine);
    const formatNumber = makeFormatNumber(Highcharts);

    class HighchartsChartView extends PureComponent {
        handleResize(width, height) {
            if (this.chart) {
                this.chart.getChart().setSize(width, height);
                return true;
            }
            return false;
        }

        render() {
            const { className } = this.props;

            const domProps = {
                className
            };

            try {
                return (
                    <HighchartComponent
                      ref={(c) => {
                          this.chart = c;
                      }}
                      domProps={domProps}
                      config={configCallback(this.props, HighchartsEngine, formatNumber)}
                    />
                );
            } catch (error) {
                return <HighchartError message={error.message} />;
            }
        }
    }

    HighchartsChartView.propTypes = {
        className: PropTypes.string
    };

    HighchartsChartView.defaultProps = {
        className: ''
    };

    return HighchartsChartView;
};

export default highchartsChartViewConnector;
