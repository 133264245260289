import React from 'react';
import TableCellSettingContext from 'src/components/context/TableCellSettingContext';

const withTableCellSettingContextConsumer = (WrappedComponent) => {
    const WithTableCellSettingContextConsumer = (props) => (
        <TableCellSettingContext.Consumer>
            {
                    (cellSetting) => <WrappedComponent cellSetting={cellSetting || {}} {...props} />
            }
        </TableCellSettingContext.Consumer>
    );

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithTableCellSettingContextConsumer.displayName = `withTableCellSettingContextConsumer(${wrappedComponentName})`;

    return WithTableCellSettingContextConsumer;
};

export default withTableCellSettingContextConsumer;
