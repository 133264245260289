import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/divWithMultiLineEllipsis.scss';
import withToolTip from 'src/components/withToolTip';
import _omit from 'lodash/omit';

const DivWithMultiLineEllipsis = (props) => {
    const divWithTooltipRef = useRef(null);
    const handleMouseOver = (proxy, event) => {
        const divWithTooltip = divWithTooltipRef.current;
        if ((divWithTooltip.scrollWidth > divWithTooltip.offsetWidth) || (divWithTooltip.scrollHeight > divWithTooltip.offsetHeight)) {
            const { onMouseOver } = props;
            onMouseOver(proxy, event);
        }
    };

    const handleMouseOut = (proxy, event) => {
        const { onMouseOut } = props;
        onMouseOut(proxy, event);
    };

    const { children, lineClamps } = props;
    const cleanedProps = _omit(props, 'lineClamps');
    return (
        <div
          {...cleanedProps}
          className={styles.ellipsis}
          ref={divWithTooltipRef}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{ WebkitLineClamp: lineClamps }}
        >
            {children}
        </div>
    );
};

DivWithMultiLineEllipsis.propTypes = {
    children: PropTypes.node.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    lineClamps: PropTypes.number
};

DivWithMultiLineEllipsis.defaultProps = {
    lineClamps: 1
};

export default withToolTip(DivWithMultiLineEllipsis);
