import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import UserPost from './partials/UserPost';

const TwitterQuestion = (props) => {
    const {
        cellProps,
        pageName,
        userName,
        createTime,
        message,
        link
    } = props;
    return (
        <Cell {...cellProps}>
            <UserPost
              profileName={pageName}
              userName={userName || 'n/a'}
              interactionText="was asked"
              interactionObject="tweet"
              time={createTime}
              link={link}
              content={message}
            />
        </Cell>
    );
};

TwitterQuestion.propTypes = {
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    pageName: PropTypes.string.isRequired,
    userName: PropTypes.string,
    createTime: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};

TwitterQuestion.defaultProps = {
    userName: ''
};

export default TwitterQuestion;
