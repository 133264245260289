import {
    makeSelectActiveCombinedPostTagsById,
    makeSelectActiveCombinedPostTagsIds
} from 'src/selectors/combindedPostTags';
import styles from 'src/stylesheets/postTags.scss';
import React from 'react';
import { connect } from 'react-redux';
import ResponsiveEditableItem from 'src/components/ResponsiveEditableItem';
import PostPostTagListItem from 'src/components/views/postTags/PostPostTagListItem';
import PropTypes from 'prop-types';

const PostTagItem = ({ postTag }) => (
    <div key={postTag.id} className={styles.rowFlexParent}>
        <div className={styles.rowFlexChild}>
            <ResponsiveEditableItem name={postTag.name} />
        </div>
    </div>
);

PostTagItem.propTypes = {
    postTag: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
};

const makeMapStateToPropsForPostTagName = () => {
    const selectActivePostTagsByIds = makeSelectActiveCombinedPostTagsById();
    return (state, ownProps) => {
        const { id } = ownProps;
        return {
            postTag: selectActivePostTagsByIds(state, id)
        };
    };
};

const ConnectedPostTagItem = connect(makeMapStateToPropsForPostTagName)(PostTagItem);

const ActivePostTags = (props) => {
    const { existingPostTagIds } = props;

    if (existingPostTagIds.length > 0) {
        return (
            existingPostTagIds.map((id) => (
                <ConnectedPostTagItem key={id} id={id} />
            ))
        );
    }
    return (
        <div key="no-tags" className={styles.rowFlexParent}>
            <div className={styles.rowFlexChild}>
                <PostPostTagListItem name="No tags" disabled />
            </div>
        </div>
    );
};

ActivePostTags.propTypes = {
    existingPostTagIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectActiveCombinedPostTagsIds = makeSelectActiveCombinedPostTagsIds();
    return (state, ownProps) => {
        const { postTagIds } = ownProps;
        return {
            existingPostTagIds: selectActiveCombinedPostTagsIds(state, postTagIds)
        };
    };
};

export default connect(makeMapStateToProps)(ActivePostTags);
