import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import { money } from 'src/components/chartViews/renderers';
import styles from 'src/stylesheets/table/tableCell.scss';
import { getClassNameForNumberFormatting } from 'src/components/chartViews/fixedDataTable/tableCellRenderers/utils';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { connect } from 'react-redux';

const Money = ({
    cellProps,
    value,
    currency,
    accountOptions
}) => {
    const parsedValue = parseFloat(value);
    let colorClassName = 'notAvailable';
    if (parsedValue !== null && !window.Number.isNaN(parsedValue)) {
        colorClassName = getClassNameForNumberFormatting(parsedValue);
    }
    return (
        <Cell {...cellProps}>
            <span className={styles[colorClassName]}>
                {money(value, currency, accountOptions.numberFormat)}
            </span>
        </Cell>
    );
};

Money.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

Money.defaultProps = {
    value: null,
    currency: ''
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(Money);
