import React from 'react';
import PropTypes from 'prop-types';

const PostTagName = (props) => {
    const { name } = props;

    return <span>{name}</span>;
};

PostTagName.propTypes = {
    name: PropTypes.string.isRequired
};

export default PostTagName;
