import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { socialNetworkAdAccountAuthenticationConnectSuccess } from 'src/actions/socialNetworkAdAccountAuthentication';

export const authUsersAuthAdAccountUseCases = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return accountAuthenticationEntities.authUsersAuthAdAccountUseCases;
        }
        case socialNetworkAdAccountAuthenticationConnectSuccess.type: {
            const { authUser } = payload;
            if (authUser !== null) {
                const cleanedUpAuthUserAuthAccountIds = state.filter((authUsersAuthAdAccountUseCase) => authUsersAuthAdAccountUseCase.authUserId !== authUser.id);
                return [...cleanedUpAuthUserAuthAccountIds, ...payload.authUserAuthAdAccountUseCases];
            }
            return state;
        }
        default:
            return state;
    }
};
