import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/table.scss';

const Shadow = ({ placement, active, hasSummaryLine }) => (
    <div className={classnames(styles.shadow, styles[placement], {
        [styles.active]: active,
        [styles.summaryLineOffset]: hasSummaryLine
    })}
    />
);

Shadow.propTypes = {
    active: PropTypes.bool,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
    hasSummaryLine: PropTypes.bool
};

Shadow.defaultProps = {
    active: false,
    hasSummaryLine: false
};

export default Shadow;
