import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/tableCell.scss';

const IsSponsoredPost = (props) => {
    const {
        value, cellProps
    } = props;
    let contentToRender = null;
    if (value === null) {
        contentToRender = <span> Not yet classified </span>;
    } else if (value === 1) {
        contentToRender = <span className={styles.green}> Highly Likely </span>;
    } else {
        contentToRender = <span className={styles.red}> Highly Unlikely </span>;
    }
    return (
        <Cell {...cellProps}>
            <div className={styles.head}>
                {contentToRender}
            </div>
        </Cell>
    );
};

IsSponsoredPost.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
};

export default IsSponsoredPost;
