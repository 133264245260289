import { authUserRemoveSuccess, clearAuthUserConnectStateFromReducer } from 'src/actions/authUsers';
import {
    FACEBOOK_AUTH_USER_CONNECT_ERROR,
    FACEBOOK_AUTH_USER_CONNECT_REQUEST,
    FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
    FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_FORCE_CONNECT_ERROR,
    FACEBOOK_AUTH_USER_FORCE_CONNECT_REQUEST,
    FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS,
} from 'src/actions/graphAuthUsers';
import {
    LINKED_IN_AUTH_USER_CONNECT_ERROR,
    LINKED_IN_AUTH_USER_CONNECT_REQUEST,
    LINKED_IN_AUTH_USER_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
    LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_FORCE_CONNECT_ERROR,
    LINKED_IN_AUTH_USER_FORCE_CONNECT_REQUEST,
    LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS
} from 'src/actions/linkedInAuthUsers';
import {
    THREADS_AUTH_USER_CONNECT_ERROR,
    THREADS_AUTH_USER_CONNECT_REQUEST, THREADS_AUTH_USER_CONNECT_SUCCESS, THREADS_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    THREADS_AUTH_USER_EXTERNAL_CONNECT_REQUEST, THREADS_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/threadsAuthUsers';
import {
    TWITTER_AUTH_USER_CONNECT_ERROR,
    TWITTER_AUTH_USER_CONNECT_REQUEST,
    TWITTER_AUTH_USER_CONNECT_SUCCESS,
    TWITTER_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    TWITTER_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/twitterAuthUsers';
import {
    YOUTUBE_AUTH_USER_CONNECT_ERROR,
    YOUTUBE_AUTH_USER_CONNECT_REQUEST,
    YOUTUBE_AUTH_USER_CONNECT_SUCCESS,
    YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/youtubeAuthUsers';
import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';
import {
    SNAPCHAT_AUTH_USER_CONNECT_ERROR,
    SNAPCHAT_AUTH_USER_CONNECT_REQUEST,
    SNAPCHAT_AUTH_USER_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
    SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_FORCE_CONNECT_ERROR,
    SNAPCHAT_AUTH_USER_FORCE_CONNECT_REQUEST,
    SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS
} from 'src/actions/snapchatAuthUsers';
import {
    TIKTOK_AUTH_USER_CONNECT_ERROR,
    TIKTOK_AUTH_USER_CONNECT_REQUEST,
    TIKTOK_AUTH_USER_CONNECT_SUCCESS,
    TIKTOK_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    TIKTOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/tiktokAuthUsers';
import { EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS } from 'src/actions/socialNetworkProfileAuthentication';
import { socialNetworkAdAccountAuthenticationConnectSuccess } from 'src/actions/socialNetworkAdAccountAuthentication';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return Object.keys(accountAuthenticationEntities.authUsers);
        }
        case authUserRemoveSuccess.type: {
            const { id } = payload;
            return state.filter((value) => value !== id);
        }
        case EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS:
        case socialNetworkAdAccountAuthenticationConnectSuccess.type: {
            const { authUser } = payload;
            if (authUser !== null) {
                return _uniq([...state, authUser.id]);
            }
            return state;
        }
        default:
            return state;
    }
};

const byId = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return Object.assign({}, state, accountAuthenticationEntities.authUsers);
        }
        case authUserRemoveSuccess.type: {
            const { id } = payload;
            return _omit(state, id);
        }
        case EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS:
        case socialNetworkAdAccountAuthenticationConnectSuccess.type: {
            const { authUser } = payload;
            if (authUser !== null) {
                return Object.assign({}, state, authUser);
            }
            return state;
        }
        default:
            return state;
    }
};

const asyncStatesByAction = combineReducers({
    authenticate: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.identifier,
        [
            FACEBOOK_AUTH_USER_CONNECT_REQUEST,
            FACEBOOK_AUTH_USER_FORCE_CONNECT_REQUEST,
            FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
            FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
            LINKED_IN_AUTH_USER_CONNECT_REQUEST,
            LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
            LINKED_IN_AUTH_USER_FORCE_CONNECT_REQUEST,
            LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
            LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
            TWITTER_AUTH_USER_CONNECT_REQUEST,
            TWITTER_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
            YOUTUBE_AUTH_USER_CONNECT_REQUEST,
            YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
            SNAPCHAT_AUTH_USER_CONNECT_REQUEST,
            SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
            SNAPCHAT_AUTH_USER_FORCE_CONNECT_REQUEST,
            SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
            TIKTOK_AUTH_USER_CONNECT_REQUEST,
            TIKTOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
            THREADS_AUTH_USER_CONNECT_REQUEST,
            THREADS_AUTH_USER_EXTERNAL_CONNECT_REQUEST
        ],
        [
            FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
            FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS,
            FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
            FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
            FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
            LINKED_IN_AUTH_USER_CONNECT_SUCCESS,
            LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
            LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS,
            LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
            TWITTER_AUTH_USER_CONNECT_SUCCESS,
            TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
            YOUTUBE_AUTH_USER_CONNECT_SUCCESS,
            YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
            SNAPCHAT_AUTH_USER_CONNECT_SUCCESS,
            SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
            SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS,
            SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
            TIKTOK_AUTH_USER_CONNECT_SUCCESS,
            TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
            THREADS_AUTH_USER_CONNECT_SUCCESS,
            THREADS_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
        ],
        [
            FACEBOOK_AUTH_USER_CONNECT_ERROR,
            FACEBOOK_AUTH_USER_FORCE_CONNECT_ERROR,
            FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_ERROR,
            FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
            LINKED_IN_AUTH_USER_CONNECT_ERROR,
            LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_ERROR,
            LINKED_IN_AUTH_USER_FORCE_CONNECT_ERROR,
            LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
            TWITTER_AUTH_USER_CONNECT_ERROR,
            TWITTER_AUTH_USER_EXTERNAL_CONNECT_ERROR,
            YOUTUBE_AUTH_USER_CONNECT_ERROR,
            YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_ERROR,
            SNAPCHAT_AUTH_USER_CONNECT_ERROR,
            SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_ERROR,
            SNAPCHAT_AUTH_USER_FORCE_CONNECT_ERROR,
            SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
            TIKTOK_AUTH_USER_CONNECT_ERROR,
            TIKTOK_AUTH_USER_EXTERNAL_CONNECT_ERROR,
            THREADS_AUTH_USER_CONNECT_ERROR,
            THREADS_AUTH_USER_EXTERNAL_CONNECT_ERROR
        ],
        [clearAuthUserConnectStateFromReducer.type]
    )
});

export const authUsers = combineReducers({
    allIds,
    byId,
    asyncStatesByAction
});
