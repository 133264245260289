import _findIndex from 'lodash/findIndex';
import _has from 'lodash/has';
import _isNumber from 'lodash/isNumber';
import classnames from 'classnames';
import Number from 'src/components/Number';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/table.scss';
import * as customPropTypes from 'src/customPropTypes';
import FixedDataTableWidthHelper from './FixedDataTableWidthHelper';

const getSummaryLineColumnPrefix = (aggregationType) => {
    switch (aggregationType) {
        case 'sum':
            return <span>Σ = </span>;
        case 'average':
            return <span><span style={{ textDecoration: 'overline' }}>x</span> = </span>;
        default:
            return null;
    }
};

const SummaryLineCell = (props) => {
    const {
        column, results, summaryLine, className
    } = props;
    let summaryLineColumnValue = '';
    const resultIndex = _findIndex(summaryLine, ['id', column.id]);

    if (resultIndex >= 0) {
        const summaryLineColumnPrefix = getSummaryLineColumnPrefix(summaryLine[resultIndex].aggregationType);

        const { value } = summaryLine[resultIndex];
        const isNumber = _isNumber(value);
        if (isNumber) {
            const hasDecimals = value % 1 !== 0;

            let symbol = '';

            if (column.type === 'percent') {
                symbol = '%';
            } if (column.type === 'money') {
                const firstRow = results.getObjectAt(0);
                if (_has(firstRow, 'currency')) {
                    symbol = ` ${firstRow.currency}`;
                }
            }
            const align = FixedDataTableWidthHelper.getAlignment(column.type);
            summaryLineColumnValue = (
                <div className={styles.summaryLineRelativeWrapper}>
                    <div className={classnames(styles.summaryLineAbsoluteWrapper, styles[align])}>{summaryLineColumnPrefix}<Number value={summaryLine[resultIndex].value} decimals={hasDecimals ? 2 : 0} />{symbol}</div>
                </div>
            );
        }
    }
    return <td className={classnames(styles.wordwrap, styles.summaryLineCell, className)}>{summaryLineColumnValue}</td>;
};

SummaryLineCell.propTypes = {
    column: customPropTypes.tableCellColumn.isRequired,
    results: PropTypes.object.isRequired,
    summaryLine: PropTypes.array.isRequired,
    className: PropTypes.string
};

export default SummaryLineCell;
