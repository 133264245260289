import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';

const Error = ({ error, type, link }) => <Feedback content={error.message} type={type} link={link} responsive={false} />;

Error.propTypes = {
    error: customPropTypes.dataLoadingError.isRequired,
    type: PropTypes.string,
    link: PropTypes.element
};

Error.defaultProps = {
    type: 'error'
};

export default Error;
