import * as customPropTypes from 'src/customPropTypes';
import _capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/selectorLabels.scss';
import { tryGetTimezoneAbbrevOrUtcOffsetOrNull } from 'src/components/dateSelector/utils';

const AbsoluteSelectedDateLabel = (props) => {
    const { selectedDate, hideInterval } = props;
    let labelValue = '';
    let text = '';
    if (selectedDate) {
        const {
            from, to, interval, timezone
        } = props.selectedDate;
        const inputFormat = 'YYYY-MM-DD';
        const outputMonthDay = 'MMM D';
        const outputYear = 'YYYY';

        const fromYear = moment(from, inputFormat).format(outputYear);
        const toYear = moment(to, inputFormat).format(outputYear);

        let fromText;
        if (fromYear === toYear) {
            fromText = `${moment(from, inputFormat).format(outputMonthDay)}`;
        } else {
            fromText = `${moment(from, inputFormat).format(outputMonthDay)}, ${fromYear}`;
        }

        let toText = `${moment(to, inputFormat).format(outputMonthDay)}, ${moment(to, inputFormat).format(outputYear)}`;
        const abbrevFromOrNull = tryGetTimezoneAbbrevOrUtcOffsetOrNull(timezone, moment(`${from} 00:00:00`, `${inputFormat} HH:mm:ss`));
        const abbrevToOrNull = tryGetTimezoneAbbrevOrUtcOffsetOrNull(timezone, moment(`${to} 23:59:59`, `${inputFormat} HH:mm:ss`));

        if (abbrevFromOrNull && abbrevToOrNull) {
            if (abbrevFromOrNull !== abbrevToOrNull) {
                fromText = `${fromText} ${abbrevFromOrNull}`;
            }
            toText = `${toText} ${abbrevToOrNull}`;
        }

        text = `${fromText} - ${toText}`;
        if (!hideInterval) {
            labelValue = _capitalize(interval);
        }
    } else {
        text = 'No date selected';
        labelValue = 'Click to select';
    }

    return (
        <span>
            <span>
                {text}
            </span>
            {labelValue
            && <span className={styles.label}>({labelValue})</span>}
        </span>
    );
};

AbsoluteSelectedDateLabel.propTypes = {
    selectedDate: PropTypes.oneOfType([customPropTypes.dateRange, PropTypes.bool]).isRequired,
    hideInterval: PropTypes.bool
};

AbsoluteSelectedDateLabel.defaultProps = {
    hideInterval: false
};

export default AbsoluteSelectedDateLabel;
