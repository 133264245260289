import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';

const Overlaps = (props) => {
    const { cellProps, overlaps } = props;
    const valueToShow = (overlaps !== null) ? overlaps : 'n/a';
    return (
        <Cell {...cellProps}>
            {valueToShow}
        </Cell>
    );
};

Overlaps.propTypes = {
    overlaps: PropTypes.string,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

Overlaps.defaultProps = {
    overlaps: null
};

export default Overlaps;
