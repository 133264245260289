import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import { shortenInteger } from 'src/components/chartViews/renderers';
import { getClassNameForNumberFormatting } from 'src/components/chartViews/fixedDataTable/tableCellRenderers/utils';
import styles from 'src/stylesheets/table/tableCell.scss';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { connect } from 'react-redux';

const ShortenInteger = (props) => {
    const { value, cellProps, accountOptions } = props;
    const parsedValue = parseInt(value, 10);
    const colorClassName = getClassNameForNumberFormatting(parsedValue);

    return (
        <Cell {...cellProps}>
            <span className={styles[colorClassName]}>
                {
                    shortenInteger(value, accountOptions.numberFormat)
                }
            </span>
        </Cell>
    );
};

ShortenInteger.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

ShortenInteger.defaultProps = {
    value: null
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(ShortenInteger);
