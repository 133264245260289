import React, { Component } from 'react';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import { getColumnsToRender } from 'src/components/chartViews/fixedDataTable/utils';
import memoizeOne from 'memoize-one';
import PaginationTableFooter from 'src/components/chartViews/fixedDataTable/PaginationTableFooter';
import PropTypes from 'prop-types';
import StaticTableFooter from 'src/components/chartViews/fixedDataTable/StaticTableFooter';
import styles from 'src/stylesheets/table/table.scss';
import * as customPropTypes from 'src/customPropTypes';
import TableBodyRows from './TableBodyRows';
import ShadowHandler from './ShadowHandler';
import LoadingScreen from './LoadingScreen';
import FixedDataTableWidthHelper from './FixedDataTableWidthHelper';
import SummaryLine from './SummaryLine';
import SummaryLineCell from './SummaryLineCell';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import TableHeaderRow from './TableHeaderRow';

const createFooterWithSummaryLineRow = (columns, summaryLine, results) => {
    const columnsToRender = columns.map((column) => <SummaryLineCell key={column.id} column={column} summaryLine={summaryLine} results={results} />);
    return (
        <tfoot>
            <tr
              className={classnames(styles.tableRow, styles.lastTableRow)}
              key="tr-summaryLine"
            >
                {columnsToRender}
            </tr>
        </tfoot>
    );
};

const detectShadows = (target) => {
    const {
        scrollLeft,
        scrollTop,
        offsetHeight,
        scrollHeight,
        offsetWidth,
        scrollWidth
    } = target;

    return {
        top: scrollTop > 0,
        right: (offsetWidth < scrollWidth) && !(scrollWidth === offsetWidth + scrollLeft),
        bottom: (offsetHeight < scrollHeight) && !(scrollHeight === offsetHeight + scrollTop),
        left: scrollLeft > 0
    };
};

function setWidthBasedOnSmallestRatio(columns) {
    const minimumColumnWidth = 75;
    // find smallest ratio
    return columns.map((column) => Object.assign({}, column, {
        ratioBasedWidth: Math.floor(FixedDataTableWidthHelper.getWidthRatioOrDefault(column) * minimumColumnWidth)
    }));
}

function createHeaderRow(columnsWithDimensions, columnsSortBy, columnsSortDir, onSortChangeClick) {
    return (
        <TableHeaderRow
          columns={columnsWithDimensions}
          sortBy={columnsSortBy}
          sortDir={columnsSortDir}
          onSortChangeClick={onSortChangeClick}
        />
    );
}

function getVisibileColumnsWithAdjustedWidthBasedOnSmallestRatio(columns, hiddenColumns) {
    const visibleColumns = getColumnsToRender(columns, hiddenColumns);
    return setWidthBasedOnSmallestRatio(visibleColumns);
}

function adjustColumnWidths(visibleColumnsWithWidthBasedOnSmallestRatio, width) {
    return FixedDataTableWidthHelper.adjustColumnWidths(visibleColumnsWithWidthBasedOnSmallestRatio, width);
}

function createBodyRows(visibleColumnsWithWidthBasedOnSmallestRatio, rowsCount, columnsSortBy, columnsSortDir, results) {
    return (
        <TableBodyRows
          columns={visibleColumnsWithWidthBasedOnSmallestRatio}
          rowsCount={rowsCount}
          sortBy={columnsSortBy}
          sortDir={columnsSortDir}
          results={results}
        />
    );
}

class Table extends Component {
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.registerTableBodyAsRef = this.registerTableBodyAsRef.bind(this);

        this.memoizedCreateHeaderRow = memoizeOne(createHeaderRow);
        this.memoizedGetVisibileColumnsWithAdjustedWidthBasedOnSmallestRatio = memoizeOne(getVisibileColumnsWithAdjustedWidthBasedOnSmallestRatio);
        this.memoizedAdjustColumnWidths = memoizeOne(adjustColumnWidths);
        this.memoizedcreateBodyRows = memoizeOne(createBodyRows);
        this.memoizedcreateFooterWithSummaryLineRow = memoizeOne(createFooterWithSummaryLineRow);

        this.setVerticalScrollbarPx = this.setVerticalScrollbarPx.bind(this);

        this.state = {
            verticalScrollbarPx: 0
        };
    }

    componentDidMount() {
        const {
            top,
            right,
            bottom,
            left
        } = detectShadows(this.tableBody);
        this.shadowHandler.setShadowValues(top, right, bottom, left);
    }

    componentDidUpdate(prevProps) {
        const { width, height, results } = this.props;
        if (width !== prevProps.width || height !== prevProps.height) {
            const {
                top,
                right,
                bottom,
                left
            } = detectShadows(this.tableBody);
            this.shadowHandler.setShadowValues(top, right, bottom, left);
        }

        if (prevProps.results !== results) {
            if (_isFunction(_get(this.tableBody, 'scrollTo'))) {
                this.tableBody.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                this.tableBody.scrollTop = 0;
            }
        }
    }

    handleScroll(e) {
        const { scrollLeft } = e.nativeEvent.target;
        this.tableHeader.setOffset(-1 * scrollLeft);

        if (this.summaryLine) {
            this.summaryLine.setOffset(-1 * scrollLeft);
        }
        const {
            top,
            right,
            bottom,
            left
        } = detectShadows(e.nativeEvent.target);
        this.shadowHandler.setShadowValues(top, right, bottom, left);
    }

    setVerticalScrollbarPx(px) {
        const { verticalScrollbarPx } = this.state;
        if (px !== verticalScrollbarPx) {
            this.setState({ verticalScrollbarPx: px });
        }
    }

    registerTableBodyAsRef(component) {
        this.tableBody = component;
    }

    render() {
        const {
            width,
            height,
            rowsCount,
            paginate,
            onPaginationClick,
            onPage,
            pageLength,
            totalResultsCount,
            isLoading,
            columns,
            hiddenColumns,
            columnsSortBy,
            columnsSortDir,
            onSortChangeClick,
            results,
            tableConfig,
            summaryLine
        } = this.props;

        const { verticalScrollbarPx } = this.state;

        let tableFooter = null;
        const tableBorder = 1;
        const tableFooterHeight = 39;

        if (paginate) {
            tableFooter = (
                <PaginationTableFooter
                  onPaginationClick={onPaginationClick}
                  onPage={onPage}
                  pageLength={pageLength}
                  rowsCount={rowsCount}
                  totalResultsCount={totalResultsCount}
                  height={tableFooterHeight}
                />
            );
        } else {
            tableFooter = (
                <StaticTableFooter totalResultsCount={totalResultsCount} />
            );
        }

        const heightMinusFooter = tableFooter ? (height - tableFooterHeight - tableBorder) : height;
        const visibileColumnsWithAdjustedWidthBasedOnSmallestRatio = this.memoizedGetVisibileColumnsWithAdjustedWidthBasedOnSmallestRatio(
            columns,
            hiddenColumns
        );

        // This is a 1px buffer safety boundary for scrollbars
        const renderWidth = (width - verticalScrollbarPx - 1);

        const columnsWithDimensions = this.memoizedAdjustColumnWidths(
            visibileColumnsWithAdjustedWidthBasedOnSmallestRatio,
            renderWidth
        );

        const memoizedHeaderRow = this.memoizedCreateHeaderRow(
            columnsWithDimensions,
            columnsSortBy,
            columnsSortDir,
            onSortChangeClick
        );

        const memoizedBodyRows = this.memoizedcreateBodyRows(
            visibileColumnsWithAdjustedWidthBasedOnSmallestRatio,
            rowsCount,
            columnsSortBy,
            columnsSortDir,
            results
        );

        let summaryLineToRender = false;
        if (_get(tableConfig, 'summaryLine.enabled', false)) {
            // If there is a potential money field, we need to lookout for currency
            summaryLineToRender = this.memoizedcreateFooterWithSummaryLineRow(columnsWithDimensions, summaryLine, results);
        }
        const hasSummaryLine = !!summaryLineToRender && rowsCount > 0;
        return (
            <div style={{ width, height, position: 'relative' }}>
                <div className={styles.container} style={{ width, height: heightMinusFooter }}>
                    <TableHeader renderWidth={renderWidth} header={memoizedHeaderRow} ref={(c) => { this.tableHeader = c; }} />
                    <TableBody
                      refCallback={this.registerTableBodyAsRef}
                      header={memoizedHeaderRow}
                      body={memoizedBodyRows}
                      height={heightMinusFooter}
                      onScroll={this.handleScroll}
                      rowsCount={rowsCount}
                      outerWidth={width}
                      hasSummaryLine={hasSummaryLine}
                      setVerticalScrollbarPx={this.setVerticalScrollbarPx}
                    />
                    {
                        hasSummaryLine !== false
                            && (
                            <SummaryLine
                              renderWidth={renderWidth}
                              header={memoizedHeaderRow}
                              body={summaryLineToRender}
                              ref={(c) => { this.summaryLine = c; }}
                            />
                            )
                    }
                    <ShadowHandler hasSummaryLine={hasSummaryLine} ref={(c) => { this.shadowHandler = c; }} />
                </div>
                {tableFooter}
                {
                    isLoading && <LoadingScreen />
                }
            </div>
        );
    }
}

Table.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    rowsCount: PropTypes.number.isRequired,
    paginate: PropTypes.bool,
    tableConfig: PropTypes.object.isRequired,
    summaryLine: PropTypes.array,
    results: PropTypes.object.isRequired,
    onSortChangeClick: PropTypes.func.isRequired,
    columnsSortBy: PropTypes.string.isRequired,
    columnsSortDir: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(customPropTypes.tableCellColumn).isRequired,
    hiddenColumns: PropTypes.arrayOf(customPropTypes.hideColumn),
    onPaginationClick: PropTypes.func,
    onPage: PropTypes.number,
    pageLength: PropTypes.number,
    totalResultsCount: PropTypes.number,
    isLoading: PropTypes.bool
};

Table.defaultProps = {
    isLoading: false
};

export default Table;
