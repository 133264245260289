import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/filter.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';

const FilterItem = (props) => {
    const {
        state,
        hasCustomBinding,
        icon,
        content
    } = props;

    return (
        <div
          className={classnames(
              styles.filterItemWrapper,
              { [styles.notApplied]: (state === 'notApplied' || state === 'partiallyApplied') },
              { [styles.customBinding]: (hasCustomBinding === true) }
          )}
        >
            {
                hasCustomBinding && (
                    <IcomoonIcon icon="lock" className={styles.icon} />
                )
            }
            {icon}
            <div className={styles.contentWrapper}>
                {content}
            </div>
            {
                state === 'partiallyApplied' && (
                    <div className={styles.partiallyAppliedTextWrapper}>(partly applied)</div>
                )
            }
        </div>
    );
};

FilterItem.propTypes = {
    state: PropTypes.oneOf(['fullyApplied', 'partiallyApplied', 'notApplied']).isRequired,
    icon: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    hasCustomBinding: PropTypes.bool
};

FilterItem.defaultProps = {
    hasCustomBinding: false
};

export default FilterItem;
