import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import FlagIcon from 'src/components/FlagIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/tableCell.scss';

const Country = (props) => {
    const { countryCode, countryName, cellProps } = props;
    let ValueToShow = 'n/a';
    if (countryCode !== null && countryName !== null) {
        ValueToShow = (
            <div className={styles.flex}>
                <div className={styles.countryFlagIcon}>
                    <FlagIcon countryCode={countryCode} />
                </div>
                <div>
                    {countryName}
                </div>
            </div>
        );
    }
    return (
        <Cell {...cellProps}>
            {ValueToShow}
        </Cell>
    );
};

Country.propTypes = {
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

Country.defaultProps = {
    countryCode: null,
    countryName: null
};

export default Country;
