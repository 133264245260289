import FeedbackComponent from 'src/components/feedback/FeedbackComponent';
import PropTypes from 'prop-types';
import React from 'react';
import withFlexDimensionProvider from 'src/components/withFlexDimensionProvider';
import * as customPropTypes from 'src/customPropTypes';

const FeedbackComponentWithDimension = withFlexDimensionProvider(FeedbackComponent);

const Feedback = (props) => {
    const {
        type, content, link, allowHTML, responsive, button
    } = props;

    if (responsive) {
        return (
            <FeedbackComponentWithDimension
              type={type}
              content={content}
              link={link}
              allowHTML={allowHTML}
              button={button}
            />
        );
    }
    return (
        <FeedbackComponent
          type={type}
          content={content}
          link={link}
          allowHTML={allowHTML}
          button={button}
        />
    );
};

Feedback.propTypes = {
    type: customPropTypes.feedbackTypes,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    link: PropTypes.element,
    button: PropTypes.element,
    allowHTML: PropTypes.bool,
    responsive: PropTypes.bool
};

Feedback.defaultProps = {
    allowHTML: false,
    type: 'info',
    responsive: true
};

export default Feedback;
