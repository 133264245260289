import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import { getProcessedKeyWords } from 'src/utils/string';
import CommaSeparatedValueList from './CommaSeparatedValueList';

const PostTextValueList = (props) => {
    const { input } = props;

    const includeInput = _get(input, 'postText', null);
    const excludeInput = _get(input, 'postTextExclude', null);

    const renderValue = (label) => getProcessedKeyWords(label);

    if (includeInput !== null && excludeInput !== null) {
        return (
            <div>
                <span>(</span>
                <CommaSeparatedValueList renderValue={renderValue} items={includeInput.values} conjunctionWord={includeInput.conjunction === 'all' ? 'and' : 'or'} prefix="+" />
                <span>) and (</span>
                <CommaSeparatedValueList renderValue={renderValue} items={excludeInput.values} conjunctionWord={excludeInput.conjunction === 'all' ? 'and' : 'or'} prefix="-" />
                <span>)</span>
            </div>
        );
    }

    if (excludeInput !== null) {
        return (
            <div>
                <CommaSeparatedValueList
                  items={excludeInput.values}
                  conjunctionWord={excludeInput.conjunction === 'all' ? 'and' : 'or'}
                  prefix="-"
                  renderValue={renderValue}
                />
            </div>
        );
    }

    return (
        <div>
            <CommaSeparatedValueList
              items={includeInput.values}
              conjunctionWord={includeInput.conjunction === 'all' ? 'and' : 'or'}
              prefix="+"
              renderValue={renderValue}
            />
        </div>
    );
};

PostTextValueList.propTypes = {
    input: customPropTypes.postTextIncludeAndExcludeFilter.isRequired
};

export default PostTextValueList;
