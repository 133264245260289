import React from 'react';
import SortableTableHeaderCell from 'src/components/chartViews/fixedDataTable/SortableTableHeaderCell';
import styles from 'src/stylesheets/table/table.scss';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import FixedDataTableWidthHelper from './FixedDataTableWidthHelper';

const TableHeaderRow = (props) => {
    const {
        columns, sortBy, sortDir, onSortChangeClick
    } = props;
    return (
        <thead>
            <tr className={styles.tableHeaderRow}>
                {
                    columns.map((column) => {
                        const columnsSortDir = column.id === sortBy ? sortDir : null;
                        const align = FixedDataTableWidthHelper.getAlignment(column.type);
                        return (
                            <SortableTableHeaderCell
                              className={styles[`${align}Align`]}
                              column={column}
                              width={column.absoluteWidth}
                              onSortChange={onSortChangeClick}
                              sortDir={columnsSortDir}
                              key={column.id}
                            />
                        );
                    })
                }
            </tr>
        </thead>
    );
};

TableHeaderRow.propTypes = {
    columns: PropTypes.arrayOf(customPropTypes.tableHeaderColumn).isRequired,
    sortBy: PropTypes.string.isRequired,
    sortDir: PropTypes.string.isRequired,
    onSortChangeClick: PropTypes.func.isRequired
};

export default TableHeaderRow;
