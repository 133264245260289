import React from 'react';
import { connect } from 'react-redux';
import { modalShowPostDetailInAnalysis } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import withFullyAppliedFilterContextConsumer from 'src/components/chartViews/withFullyAppliedFilterContextConsumer';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import MetricRequestContext from 'src/components/context/MetricRequestContext';

const InternalPostDetailLink = (props) => {
    const {
        postId,
        fullyAppliedFilters,
        modalShowPostDetailInAnalysisAction,
        linkNode
    } = props;
    const postTextValues = _get(fullyAppliedFilters, ['postText', 'values'], []);
    return (
        <MetricRequestContext.Consumer>
            {
                (serverRequest) => (
                    <a onClick={() => { modalShowPostDetailInAnalysisAction(postId, postTextValues, serverRequest.request); }}>
                        { linkNode || 'View details' }
                    </a>
                )
            }
        </MetricRequestContext.Consumer>
    );
};

InternalPostDetailLink.propTypes = {
    postId: PropTypes.string.isRequired,
    linkNode: PropTypes.node,
    fullyAppliedFilters: customPropTypes.fullyAppliedFilters.isRequired,
    modalShowPostDetailInAnalysisAction: PropTypes.func.isRequired
};

export default withFullyAppliedFilterContextConsumer(connect(null, {
    modalShowPostDetailInAnalysisAction: modalShowPostDetailInAnalysis
})(InternalPostDetailLink));
