import AuthenticatorContext from 'src/components/context/AuthenticatorContext';
import Error from 'src/components/errors/Error';
import FeedbackLinkPlain from 'src/components/feedback/FeedbackLinkPlain';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/appError.scss';

function AppError(props) {
    const {
        error,
        withLogout
    } = props;
    const link = withLogout ? (
        <AuthenticatorContext.Consumer>
            {
                (authenticator) => (
                    <FeedbackLinkPlain
                      type="error"
                      onClick={authenticator.logout}
                    >
                        Logout
                    </FeedbackLinkPlain>
                )
            }
        </AuthenticatorContext.Consumer>
    ) : undefined;

    return (
        <div className={styles.appError}>
            <div className={styles.feedback}>
                <Error error={error} link={link} />
            </div>
        </div>
    );
}

AppError.propTypes = {
    error: customPropTypes.dataLoadingError.isRequired,
    withLogout: PropTypes.bool
};

AppError.defaultProps = {
    withLogout: true
};

export default AppError;
