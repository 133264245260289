import {
    BULK_PROFILE_ADD_ERROR,
    BULK_PROFILE_ADD_REQUEST,
    ONBOARDING_BULK_PROFILE_ADD_REQUEST,
    ONBOARDING_BULK_PROFILE_ADD_ERROR,
    ONBOARDING_BULK_PROFILE_ADD_SUCCESS,
    BULK_PROFILE_ADD_SUCCESS,
    PROFILE_ADD_STATE_CLEANUP,
    PROFILE_ADD_ERROR,
    PROFILE_ADD_REQUEST,
    PROFILE_ADD_SUCCESS,
    PROFILE_CUSTOMIZE_SUCCESS,
    PROFILE_DELETE_SUCCESS,
    PROFILE_FORCE_DELETE_SUCCESS,
    UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS,
    UPDATE_INSTAGRAM_PLATFORM_USERNAME_REQUEST,
    UPDATE_INSTAGRAM_PLATFORM_USERNAME_ERROR,
    UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS,
    UPDATE_TIKTOK_PLATFORM_USERNAME_ERROR,
    UPDATE_TIKTOK_PLATFORM_USERNAME_REQUEST
} from 'src/actions/profiles';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { GET_AUTH_TRANSACTION_BY_HASH_SUCCESS } from 'src/actions/authTransactions';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import {
    createAsyncStatesReducerForActionsWithErrorAndSuccessStates,
    createAsyncStatesReducerForAction
} from 'src/reducers/utils';
import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';

function allIds(state = [], action) {
    const { payload, type } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case PREPARE_EXPORT_DATA:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            return Object.keys(payload.profiles);
        }
        case BULK_PROFILE_ADD_SUCCESS:
        case ONBOARDING_BULK_PROFILE_ADD_SUCCESS: {
            return _uniq([...state, ...Object.keys(payload.profiles)]);
        }
        case PROFILE_DELETE_SUCCESS:
        case PROFILE_FORCE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            return _difference(state, profileIds);
        }
        default:
            return state;
    }
}

export function byId(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case PREPARE_EXPORT_DATA:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case ACCOUNT_ENTITIES_GET_SUCCESS:
        case BULK_PROFILE_ADD_SUCCESS:
        case ONBOARDING_BULK_PROFILE_ADD_SUCCESS: {
            return Object.assign({}, state, payload.profiles);
        }
        case PROFILE_CUSTOMIZE_SUCCESS:
        case UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS:
        case UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS: {
            const { profile } = payload;
            return Object.assign({}, state, { [profile.id]: profile });
        }
        case PROFILE_DELETE_SUCCESS:
        case PROFILE_FORCE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            return _omit(state, profileIds);
        }
        default:
            return state;
    }
}

const asyncStates = combineReducers({
    adding: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.id,
        [PROFILE_ADD_REQUEST],
        [PROFILE_ADD_SUCCESS],
        [PROFILE_ADD_ERROR],
        [PROFILE_ADD_STATE_CLEANUP]
    ),
    bulkAdding: createAsyncStatesReducerForAction(
        () => 'state',
        [
            BULK_PROFILE_ADD_REQUEST,
            ONBOARDING_BULK_PROFILE_ADD_REQUEST
        ],
        [
            BULK_PROFILE_ADD_SUCCESS,
            BULK_PROFILE_ADD_ERROR,
            ONBOARDING_BULK_PROFILE_ADD_ERROR,
            ONBOARDING_BULK_PROFILE_ADD_SUCCESS
        ]
    ),
    instagramPlatformUsernameUpdating: createAsyncStatesReducerForAction(
        (payload) => payload.profileId,
        [UPDATE_INSTAGRAM_PLATFORM_USERNAME_REQUEST],
        [UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS, UPDATE_INSTAGRAM_PLATFORM_USERNAME_ERROR]
    ),
    tiktokPlatformUsernameUpdating: createAsyncStatesReducerForAction(
        (payload) => payload.profileId,
        [UPDATE_TIKTOK_PLATFORM_USERNAME_REQUEST],
        [UPDATE_TIKTOK_PLATFORM_USERNAME_ERROR, UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS]
    )
});

export const profiles = combineReducers({
    allIds,
    byId,
    asyncStates
});
