import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styles from 'src/stylesheets/responsiveEditableItem.scss';

const ResponsiveEditableItem = (props) => {
    const {
        name, onMouseOut, onMouseOver, disabled, className
    } = props;

    const optionalProps = {};
    if (onMouseOut) {
        optionalProps.onMouseOut = onMouseOut;
    }
    if (onMouseOut) {
        optionalProps.onMouseOver = onMouseOver;
    }

    return (
        <div
          className={classnames(styles.wrapper, className, { [styles.disabled]: disabled })}
          {...optionalProps}
        >
            <DivWithTooltip
              tooltip={name}
              className={styles.name}
            >
                {name}
            </DivWithTooltip>
        </div>
    );
};

ResponsiveEditableItem.propTypes = {
    name: PropTypes.string.isRequired,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

ResponsiveEditableItem.defaultProps = {
    disabled: false,
    className: ''
};

export default memo(ResponsiveEditableItem);
