import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import '../../icons/style.css';
import _range from 'lodash/range';

const MultiPathIcomoonIcon = (props) => {
    const {
        icon,
        numberOfPaths,
        className,
        onClick,
        onMouseOver,
        onMouseOut,
        onFocus,
        onBlur,
        active,
        activeClassName
    } = props;

    const optionalProps = {};

    if (onClick) {
        optionalProps.onClick = onClick;
    }
    if (onMouseOver) {
        optionalProps.onMouseOver = onMouseOver;
    }
    if (onMouseOut) {
        optionalProps.onMouseOut = onMouseOut;
    }
    if (onFocus) {
        optionalProps.onFocus = onFocus;
    }
    if (onBlur) {
        optionalProps.onBlur = onBlur;
    }
    return (
        <span className={classnames(className, `oiuhb-icon-${icon}`, { [activeClassName]: active })}>
            {
                _range(numberOfPaths).map((pathNumber) => (
                    <span key={pathNumber} className={`path${pathNumber + 1}`} />
                ))
            }
        </span>
    );
};

MultiPathIcomoonIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    numberOfPaths: PropTypes.number.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    active: PropTypes.bool,
    activeClassName: PropTypes.string,
};

MultiPathIcomoonIcon.defaultProps = {
    className: '',
    activeClassName: '',
    active: false
};

export default MultiPathIcomoonIcon;
