import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/postTags/postTagging/postImage.scss';
import ImageWithFallback from 'src/components/ImageWithFallback';
import textPostImage from 'src/resources/illustrations/text_post.png';
import noImage from 'src/resources/illustrations/no_image.png';
import withLazyLoad from 'src/components/lazyLoad/withLazyLoad';

const ImageWithFallbackWithLazyLoad = withLazyLoad(ImageWithFallback);

const PostImage = (props) => {
    const {
        url, width, height, lazyLoad
    } = props;
    const finalImage = url || textPostImage;
    return (
        <div style={{ width, height }}>
            {
                lazyLoad
                && (
                    <ImageWithFallbackWithLazyLoad
                      height={height}
                      width={width}
                      src={finalImage}
                      fallbackSrc={noImage}
                      className={styles.img}
                    />
                )
            }
            {
                !lazyLoad
                && (
                    <ImageWithFallback
                      height={height}
                      width={width}
                      src={finalImage}
                      fallbackSrc={noImage}
                      className={styles.img}
                    />
                )
            }
        </div>
    );
};

PostImage.propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lazyLoad: PropTypes.bool
};

PostImage.defaultProps = {
    width: 128,
    height: 128,
    lazyLoad: true
};

export default PostImage;
