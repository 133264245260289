import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';

import { PROFILE_DELETE_SUCCESS, PROFILE_FORCE_DELETE_SUCCESS } from 'src/actions/profiles';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

export const profileUseCasesAuthUsers = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return accountAuthenticationEntities.profileUseCasesAuthUsers;
        }
        case PROFILE_DELETE_SUCCESS:
        case PROFILE_FORCE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            let newMapping = state;

            profileIds.forEach((profileId) => {
                newMapping = newMapping.filter((useCaseAuthUser) => useCaseAuthUser.profileId !== profileId);
            });

            return newMapping;
        }
        default:
            return state;
    }
};
