import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/errorBody.scss';

const trialUserHistoricDataErrorMessage = <span>To learn more about our available data for paid and trial plans, visit our <Link target="_blank" to="https://help.facelift-cloud.com/en/articles/8624821-data-availability-of-networks-on-private-and-public-level-Available-Data">knowledge base</Link> for more information.</span>;
const dataSourceAccessNotAllowedInMetricBuilderMessage = <span>To learn more, check out our <Link target="_blank" to="https://help.facelift-cloud.com/en/articles/8624820-public-youtube-data-30-day-limitation">knowledge base</Link>.</span>;

const ErrorBody = (props) => {
    const {
        error,
        routePushAction,
        showRawErrors
    } = props;
    const { message, errorType } = error;

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.text}>
                    {
                        showRawErrors
                            ? message
                            : <Feedback type="error" content={message} responsive={false} />
                    }
                </div>
                {
                    errorType === 'noProfileHasPrivateStatisticsUseCasesActivated'
                        && (
                        <div className={styles.actions}>
                            <Button
                              label="Go to profiles section"
                              action
                              onClick={() => { routePushAction('/profiles'); }}
                            />
                        </div>
                        )
                }
                {
                    errorType === 'privateStatisticsUseCaseViolation'
                    && (
                    <div className={styles.actions}>
                        <Button
                          label="Go to authentications section"
                          action
                          onClick={() => {
                              routePushAction({
                                  pathname: '/authentications',
                                  state: { openBrokenProfileUseCaseMenu: true }
                              });
                          }}
                        />
                    </div>
                    )
                }
                {
                    errorType === 'trialUserHistoricDataError'
                    && (
                    <span className={styles.text}>
                        {
                            showRawErrors
                                ? trialUserHistoricDataErrorMessage
                                : <Feedback type="error" content={trialUserHistoricDataErrorMessage} responsive={false} />
                        }
                    </span>
                    )
                }
                {
                    errorType === 'dataSourceAccessNotAllowedInMetricBuilder'
                    && (
                    <span className={styles.text}>
                        {
                            showRawErrors
                                ? dataSourceAccessNotAllowedInMetricBuilderMessage
                                : (
                                    <Feedback
                                      type="error"
                                      content={dataSourceAccessNotAllowedInMetricBuilderMessage}
                                      responsive={false}
                                    />
                                )
                        }
                    </span>
                    )
                }
            </div>
        </div>
    );
};

ErrorBody.propTypes = {
    error: customPropTypes.dataLoadingError.isRequired,
    routePushAction: PropTypes.func.isRequired,
    showRawErrors: PropTypes.bool
};

ErrorBody.defaultProps = {
    showRawErrors: true
};

export default connect(null, {
    routePushAction: routeActions.push
})(ErrorBody);
