import _get from 'lodash/get';

function getAlignment(type) {
    const types = ['integer', 'shortenInteger', 'decimal', 'percent', 'money'];
    return (types.indexOf(type) !== -1) ? 'right' : 'left';
}

function getTotalWidth(columns) {
    let totalWidth = 0;
    for (let i = 0; i < columns.length; ++i) {
        totalWidth += columns[i].ratioBasedWidth;
    }
    return totalWidth;
}

function getWidthRatioOrDefault(column) {
    // negative or values below 0 are not allowed. The validator checks it but we don't allow
    return Math.max(_get(column, 'widthRatio', 1), 1);
}

function getTotalFlexGrow(columns) {
    let totalFlexGrow = 0;
    for (let i = 0; i < columns.length; ++i) {
        totalFlexGrow += getWidthRatioOrDefault(columns[i]);
    }
    return totalFlexGrow;
}

function distributeFlexWidth(columns, flexWidth) {
    if (flexWidth <= 0) {
        return {
            columns: columns.map((column) => Object.assign({}, column, { absoluteWidth: column.ratioBasedWidth })),
            width: getTotalWidth(columns),
        };
    }
    let remainingFlexGrow = getTotalFlexGrow(columns);
    let remainingFlexWidth = flexWidth;
    const newColumns = [];
    let totalWidth = 0;
    for (let i = 0; i < columns.length; ++i) {
        const column = columns[i];
        const ratioWidthFactor = getWidthRatioOrDefault(column);
        const columnFlexWidth = Math.floor((ratioWidthFactor / remainingFlexGrow) * remainingFlexWidth);
        const newColumnWidth = Math.floor(column.ratioBasedWidth + columnFlexWidth);
        totalWidth += newColumnWidth;
        remainingFlexGrow -= ratioWidthFactor;
        remainingFlexWidth -= columnFlexWidth;
        newColumns.push(Object.assign({}, column, { absoluteWidth: newColumnWidth }));
    }

    return {
        columns: newColumns,
        width: totalWidth,
    };
}

function adjustColumnWidths(columnsWithRatioBasedWidth, expectedWidth) {
    const columnsWidth = getTotalWidth(columnsWithRatioBasedWidth);
    let columnsWithDimensions;

    if (columnsWidth < expectedWidth) {
        columnsWithDimensions = distributeFlexWidth(columnsWithRatioBasedWidth, expectedWidth - columnsWidth).columns;
    } else {
        columnsWithDimensions = columnsWithRatioBasedWidth.map((column) => Object.assign({}, column, { absoluteWidth: column.ratioBasedWidth }));
    }
    return columnsWithDimensions;
}

const FixedDataTableWidthHelper = {
    adjustColumnWidths,
    getAlignment,
    getWidthRatioOrDefault
};

export default FixedDataTableWidthHelper;
