import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/table/table.scss';

class SummaryLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            left: 0
        };
    }

    setOffset(offset) {
        const { left } = this.state;
        if (left !== offset) {
            this.setState({ left: offset });
        }
    }

    render() {
        const { left } = this.state;
        const { renderWidth, header, body } = this.props;
        return (
            <div className={styles.summaryLine} style={{ width: renderWidth }}>
                <table className={styles.table} style={{ left }}>
                    {header}
                    {body}
                </table>
            </div>
        );
    }
}

SummaryLine.propTypes = {
    header: PropTypes.node.isRequired,
    body: PropTypes.node.isRequired,
    renderWidth: PropTypes.number.isRequired
};

export default SummaryLine;
