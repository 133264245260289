import { selectAllCampaignIds, selectFaceliftCampaignById } from 'src/reducers/slices/faceliftCampaigns';
import { selectAllTagIds, selectFaceliftTagById } from 'src/reducers/slices/faceliftTags';
import { getActivePostTagIds, makeSelectActivePostTagById } from 'src/selectors/postTags';
import { createSelector } from 'reselect';
import _startsWith from 'lodash/startsWith';
import _uniq from 'lodash/uniq';
import _intersection from 'lodash/intersection';

export const makeSelectActiveCombinedPostTagsById = () => {
    const selectActivePostTagById = makeSelectActivePostTagById();
    return createSelector(
        [
            (state, postTagId) => {
                if (_startsWith(postTagId, 'fl-tag')) {
                    return selectFaceliftTagById(state, postTagId);
                }
                if (_startsWith(postTagId, 'fl-cmp')) {
                    return selectFaceliftCampaignById(state, postTagId);
                }
                return selectActivePostTagById(state, postTagId);
            },
        ],
        (tag) => tag
    );
};

export const makeSelectActiveCombinedPostTagsIds = () => createSelector(
    [
        getActivePostTagIds,
        selectAllTagIds,
        selectAllCampaignIds,
        (_, postIds) => postIds
    ],
    (activePostTagIds, allTagIds, allCampaignIds, postIds) => {
        const unique = _uniq([...activePostTagIds, ...allTagIds, ...allCampaignIds]);
        return _intersection(unique, postIds);
    }
);
