import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/paginationTableFooter.scss';

const PaginationTableFooter = (props) => {
    const {
        height, totalResultsCount, onPage, rowsCount, pageLength, onPaginationClick
    } = props;

    let startOfResults = (onPage * pageLength) + 1;
    let endOfResults = (startOfResults + rowsCount) - 1;

    if (totalResultsCount < 1) {
        startOfResults = 0;
        endOfResults = 0;
    }

    const hasPrevPage = onPage !== 0;
    const hasNextPage = endOfResults < totalResultsCount;
    return (
        <div className={styles.footer} style={{ height }}>
            <div className={styles.info}>
                Showing {startOfResults} to {endOfResults} of {totalResultsCount} rows
            </div>
            <div className={styles.paginationLinks}>
                <div
                  className={classnames(styles.prevLink, { [styles.active]: hasPrevPage, [styles.nonActive]: !hasPrevPage })}
                  onClick={() => { onPaginationClick(onPage - 1); }}
                >
                    <IcomoonIcon icon="chevron-small-left" className={styles.prevIcon} />
                    <span>Previous</span>
                </div>
                <div
                  className={classnames(styles.nextLink, { [styles.active]: hasNextPage, [styles.nonActive]: !hasNextPage })}
                  onClick={() => { onPaginationClick(onPage + 1); }}
                >
                    <span>Next</span>
                    <IcomoonIcon icon="chevron-small-right" className={styles.nextIcon} />
                </div>
            </div>
        </div>
    );
};

PaginationTableFooter.propTypes = {
    height: PropTypes.number,
    totalResultsCount: PropTypes.number,
    onPage: PropTypes.number,
    pageLength: PropTypes.number,
    rowsCount: PropTypes.number,
    onPaginationClick: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

PaginationTableFooter.defaultProps = {
    loading: false
};

export default PaginationTableFooter;
