import NetworkIcon from 'src/components/NetworkIcon';
import { useSelector } from 'react-redux';
import { selectPlatformTypeByAdAccountId } from 'src/selectors/adAccounts';
import React from 'react';
import PropTypes from 'prop-types';

const AdAccountNetworkIcon = ({ disabled, adAccountId, className }) => {
    const platformType = useSelector((state) => selectPlatformTypeByAdAccountId(state, adAccountId));
    return <NetworkIcon platformType={platformType} disabled={disabled} className={className} />;
};

AdAccountNetworkIcon.propTypes = {
    adAccountId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

AdAccountNetworkIcon.defaultProps = {
    disabled: false,
    className: ''
};

export default AdAccountNetworkIcon;
