import AdAccountNetworkIcon from 'src/components/AdAccountNetworkIcon';
import IcomoonIcon from 'src/components/IcomoonIcon';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/profileSelectorButtonIcon.scss';
import PropTypes from 'prop-types';
import ProfileNetworkIcon from 'src/components/ProfileNetworkIcon';

const ProfileSelectorButtonIcon = ({ selectedProfilesOrGroups, disabled }) => {
    const { groupIds, profileIds, adAccountIds } = selectedProfilesOrGroups;
    const count = (groupIds ? groupIds.length : 0) + (profileIds ? profileIds.length : 0) + (adAccountIds ? adAccountIds.length : 0);
    if (count === 0) {
        return <IcomoonIcon icon="profiles" className={styles.wrapper} />;
    }
    if (count === 1) {
        // // if it's a profile selection, show the network icon
        if (profileIds && profileIds.length === 1) {
            return <ProfileNetworkIcon profileId={profileIds[0]} disabled={disabled} className={styles.wrapper} />;
        }
        if (adAccountIds && adAccountIds.length === 1) {
            return <AdAccountNetworkIcon adAccountId={adAccountIds[0]} disabled={disabled} className={styles.wrapper} />;
        }
        // if it's a group, show the group icon
        return <IcomoonIcon icon="folder" className={styles.wrapper} />;
    }
    // Multiple selection case
    return <IcomoonIcon icon="profiles" className={styles.wrapper} />;
};

ProfileSelectorButtonIcon.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    disabled: PropTypes.bool
};

ProfileSelectorButtonIcon.defaultProps = {
    disabled: false
};

export default ProfileSelectorButtonIcon;
