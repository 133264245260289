import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/tableCell.scss';

const FacebookUser = (props) => {
    const {
        userName, picture, profileLink, cellProps, cellSetting
    } = props;
    const { showLink } = cellSetting;
    return (
        <Cell {...cellProps}>
            <div className={styles.head}>
                <div className={styles.flex}>
                    <div>
                        <img src={picture} alt={userName} className={styles.userPicture} />
                    </div>
                    <div className={styles.right}>
                        <div dir="auto" className={styles.rightItem}>{userName}</div>
                        {
                            showLink !== 'none' && (
                                <div className={styles.rightItem}>
                                    <div className={styles.link}>
                                        <a href={profileLink} target="_blank" rel="noopener noreferrer">
                                            View profile
                                        </a>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Cell>
    );
};

FacebookUser.propTypes = {
    userName: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    profileLink: PropTypes.string.isRequired,
    cellSetting: customPropTypes.tableCellSetting.isRequired,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

export default FacebookUser;
