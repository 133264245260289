import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ImageWithFallback extends Component {
    constructor(props) {
        super(props);

        this.handleError = this.handleError.bind(this);

        this.state = {
            errored: false,
            prevPropsSrc: props.src
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.src !== state.prevPropsSrc) {
            return {
                prevPropsSrc: props.src,
                errored: false
            };
        }
        return null;
    }

    handleError() {
        this.setState({
            errored: true
        });
    }

    render() {
        const { src, fallbackSrc, ...props } = this.props;
        const { errored } = this.state;

        if (errored) {
            return <img src={fallbackSrc} {...props} />;
        }

        return <img src={src} onError={this.handleError} {...props} />;
    }
}

ImageWithFallback.propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string.isRequired
};

export default ImageWithFallback;
