import _isNumber from 'lodash/isNumber';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/feedback.scss';
import * as customPropTypes from 'src/customPropTypes';

const FeedbackComponent = (props) => {
    const {
        type, content, link, allowHTML, width, button
    } = props;

    let icon = null;
    switch (type) {
        case 'info':
            icon = 'info';
            break;

        case 'warning':
            icon = 'warning';
            break;

        case 'error':
            icon = 'error';
            break;

        case 'success':
            icon = 'check-mark';
            break;

        default:
            icon = 'info';
    }

    // we automatically inject the type from our Feedback component down to the FeedbackLink
    let linkWithType = null;
    if (link) {
        linkWithType = React.cloneElement(link, { type });
    }

    const small = _isNumber(width) ? (Math.round(width) < 440) : false;
    return (
        <div className={classnames(styles.feedbackWrapper, { [styles.small]: small }, styles[type])}>
            <div className={styles.iconWrapper}>
                <IcomoonIcon icon={icon} className={styles.icon} />
            </div>
            {
                allowHTML
                && <div dangerouslySetInnerHTML={{ __html: content }} className={styles.contentWrapper} />
            }
            {
                !allowHTML
                && (
                <div className={styles.contentWrapper}>
                    {content}
                </div>
                )
            }
            {
                link
                && (
                <div className={styles.linkWrapper}>
                    {linkWithType}
                </div>
                )
            }
            {
                button
                && (
                <div className={styles.buttonWrapper}>
                    {button}
                </div>
                )
            }
        </div>
    );
};

FeedbackComponent.propTypes = {
    type: customPropTypes.feedbackTypes,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    link: PropTypes.element,
    button: PropTypes.element,
    allowHTML: PropTypes.bool,
    width: PropTypes.number
};

FeedbackComponent.defaultProps = {
    allowHTML: false,
    type: 'info',
    width: null
};

export default FeedbackComponent;
