import { combineReducers } from 'redux';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { ADMIN_SETTINGS_GET_SETTINGS_SUCCESS } from 'src/actions/adminSettings';
import { LIMITS_GET_SUCCESS } from 'src/actions/limits';

export const clientSetting = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case PREPARE_EXPORT_DATA:
        case USER_LOGGED_IN: {
            const { clientSetting: setting } = payload;
            return setting;
        }
        default:
            return state;
    }
};

export function clientLimits(state = { limits: {}, usage: {}, freeLimits: {} }, action) {
    const { payload, type } = action;
    switch (type) {
        case ADMIN_SETTINGS_GET_SETTINGS_SUCCESS:
        case LIMITS_GET_SUCCESS: {
            const { clientLimits: clientLimitsFromPayload } = payload;
            return Object.assign({}, state, clientLimitsFromPayload);
        }
        default:
            return state;
    }
}

export const client = combineReducers({
    clientSetting,
    clientLimits
});
