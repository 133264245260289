import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import ProfileVideo from './partials/ProfileVideo';

const YoutubePlaylist = (props) => {
    const {
        cellProps,
        pageName,
        createTime,
        link,
        title,
        internalId,
        cellSetting
    } = props;
    return (
        <Cell {...cellProps}>
            <ProfileVideo
              profileName={pageName}
              time={createTime}
              link={link}
              linkText="View playlist"
              title={title}
              internalId={internalId}
              cellSetting={cellSetting}
            />
        </Cell>
    );
};

YoutubePlaylist.propTypes = {
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    pageName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string,
    internalId: PropTypes.string,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

YoutubePlaylist.defaultProps = {
    title: ''
};

export default YoutubePlaylist;
