import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import { isValidHttpUrl } from 'src/utils/string';

const Link = (props) => {
    const {
        cellProps,
        link
    } = props;
    const isValid = isValidHttpUrl(link);
    return (
        <Cell {...cellProps}>
            {
                isValid && (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                )
            }
            {
                !isValid && (
                    <>{link || 'No link'}</>
                )
            }
        </Cell>
    );
};

Link.propTypes = {
    link: PropTypes.string,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

Link.defaultProps = {
    link: ''
};

export default Link;
