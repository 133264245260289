import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/table/compactPost.scss';
import { parseExternalLinks } from 'src/parsers';
import IcomoonIcon from 'src/components/IcomoonIcon';
import InternalPostDetailLink from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/InternalPostDetailLink';
import PostImage from 'src/components/views/postTags/postTagging/PostImage';
import DOMPurify from 'dompurify';

const CompactPostPartial = (props) => {
    const {
        profileName,
        content,
        link,
        internalId,
        picture,
        cellSetting
    } = props;

    const { showLink } = cellSetting;

    return (
        <div className={styles.wrapper}>
            <div className={styles.pictureWrapper}>
                <PostImage
                  url={picture}
                  width={50}
                  height={50}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.head}>
                    <span className={styles.bold} dir="auto">{profileName}</span>
                </div>
                <div className={styles.body}>
                    {
                        content && (
                            <div className={styles.content} dangerouslySetInnerHTML={{ __html: parseExternalLinks(DOMPurify.sanitize(content)) }} />
                        )
                    }
                </div>
            </div>
            <div className={styles.linkWrapper}>
                {
                    (internalId && showLink === 'in-app')
                    && <InternalPostDetailLink postId={internalId} linkNode={<IcomoonIcon icon="vist-page" className={styles.linkIcon} />} />
                }
                {
                    (link && (showLink === 'public' || !internalId))
                    && (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <IcomoonIcon icon="vist-page" className={styles.linkIcon} />
                        </a>
                    )
                }
            </div>
        </div>
    );
};

CompactPostPartial.propTypes = {
    profileName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    internalId: PropTypes.string,
    additionalCustomLink: PropTypes.node,
    content: PropTypes.string,
    picture: PropTypes.string,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

CompactPostPartial.defaultProps = {
    additionalCustomLink: undefined,
    content: '',
    picture: ''
};

export default CompactPostPartial;
