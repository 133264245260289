import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/image.scss';
import ImageWithFallback from 'src/components/ImageWithFallback';
import fallBackImg from 'src/resources/illustrations/no_image.png';

const Image = (props) => {
    const {
        cellProps,
        imageUrl
    } = props;

    return (
        <Cell {...cellProps}>
            {
                imageUrl && (
                    <div className={styles.imageWrapper}>
                        <ImageWithFallback
                          src={imageUrl}
                          fallbackSrc={fallBackImg}
                          className={styles.image}
                        />
                    </div>
                )
            }
            {
                !imageUrl && (
                    <>&nbsp;</>
                )
            }
        </Cell>
    );
};

Image.propTypes = {
    imageUrl: PropTypes.string,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

Image.defaultProps = {
    imageUrl: ''
};

export default Image;
