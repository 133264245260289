import PropTypes from 'prop-types';
import React from 'react';
import NameAndCreateTime from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/NameAndCreateTime';
import Post from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/Post';
import * as customPropTypes from 'src/customPropTypes';

const ProfilePost = (props) => {
    const {
        profileName,
        time,
        content,
        link,
        internalId,
        linkText,
        additionalCustomLink,
        picture,
        cellSetting
    } = props;

    const contentItems = [];
    if (content) {
        contentItems.push({
            id: 'message',
            content
        });
    }

    return (
        <Post
          headline={<NameAndCreateTime name={profileName} createTime={time} />}
          link={link}
          internalId={internalId}
          linkText={linkText}
          additionalCustomLink={additionalCustomLink}
          picture={picture}
          contentItems={contentItems}
          cellSetting={cellSetting}
        />
    );
};

ProfilePost.propTypes = {
    profileName: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    additionalCustomLink: PropTypes.node,
    content: PropTypes.string,
    picture: PropTypes.string,
    internalId: PropTypes.string,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

ProfilePost.defaultProps = {
    linkText: 'View post',
    additionalCustomLink: undefined,
    content: '',
    picture: ''
};

export default ProfilePost;
