import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/tableCell.scss';

const NameAndCreateTime = (props) => {
    const { name, createTime } = props;
    return (
        <span className={styles.bold}>
            <span dir="auto">{name}</span>
            {
                createTime
                && <span> - {createTime}</span>
            }
        </span>
    );
};

NameAndCreateTime.propTypes = {
    name: PropTypes.string.isRequired,
    createTime: PropTypes.string
};

NameAndCreateTime.defaultProps = {
    createTime: ''
};

export default NameAndCreateTime;
