import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styles from 'src/stylesheets/views/postTags/postPostTagListItem.scss';

const PostPostTagListItem = (props) => {
    const {
        name, onMouseOut, onMouseOver, disabled, className
    } = props;

    const optionalProps = {};
    if (onMouseOut) {
        optionalProps.onMouseOut = onMouseOut;
    }
    if (onMouseOut) {
        optionalProps.onMouseOver = onMouseOver;
    }

    return (
        <div
          className={classnames(styles.wrapper, className, { [styles.disabled]: disabled })}
          {...optionalProps}
        >
            <div className={styles.name}>
                {name}
            </div>
        </div>
    );
};

PostPostTagListItem.propTypes = {
    name: PropTypes.string.isRequired,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

PostPostTagListItem.defaultProps = {
    disabled: false,
    className: ''
};

export default memo(PostPostTagListItem);
