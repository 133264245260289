import React from 'react';
import PropTypes from 'prop-types';

const wrapInSingleQuotesWithPrefix = (text, prefix, index) => <span key={`${index}-content`}>{prefix}&apos;{text}&apos;</span>;

const CommaSeparatedValueList = (props) => {
    const {
        items, conjunctionWord, prefix, renderValue
    } = props;

    const listElements = [];
    for (let i = 0; i < items.length; i++) {
        if (i === 0) {
            // First element has no comma before
            listElements.push(wrapInSingleQuotesWithPrefix(renderValue(items[i]), prefix, i));
        } else if ((i + 1) < items.length) {
            // Elements 1...(n-1) get just concatenated by comma
            listElements.push(<span key={`${i}-conjunction`}>, </span>);
            listElements.push(wrapInSingleQuotesWithPrefix(renderValue(items[i]), prefix, i));
        } else {
            listElements.push(<span key={`${i}-conjunction`}> {conjunctionWord} </span>);
            listElements.push(wrapInSingleQuotesWithPrefix(renderValue(items[i]), prefix, i));
        }
    }

    return (
        <span>
            {listElements.map((element) => element)}
        </span>
    );
};

CommaSeparatedValueList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.node).isRequired,
    conjunctionWord: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    renderValue: PropTypes.func
};

CommaSeparatedValueList.defaultProps = {
    prefix: '',
    renderValue: (value) => value
};

export default CommaSeparatedValueList;
