import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/postTags.scss';
import ActivePostTags from 'src/components/chartViews/ActivePostTags';
import { parsePostPostTags } from 'src/parsers';

const PostTags = ({ value, cellProps }) => {
    let tagsArray;
    try {
        tagsArray = parsePostPostTags(JSON.parse(value));
    } catch (e) {
        tagsArray = {};
    }

    const combinedClassName = classnames(styles.cell, cellProps.className);
    const cellPropsWithCombinedClassName = Object.assign({}, cellProps);
    cellPropsWithCombinedClassName.className = combinedClassName;
    const postTagIds = Object.keys(tagsArray);

    return (
        <Cell {...cellPropsWithCombinedClassName}>
            <div className={styles.columnFlexParent}>
                <ActivePostTags postTagIds={postTagIds} />
            </div>
        </Cell>
    );
};

PostTags.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

PostTags.defaultProps = {
    value: '[]'
};

export default PostTags;
