import LazyLoad from 'src/components/lazyLoad/LazyLoad';
import PropTypes from 'prop-types';
import React from 'react';
import _omit from 'lodash/omit';

const withLazyLoading = (WrappedComponent, offsetDefault = 450, debounceDefault = false, throttleDefault = 250) => {
    const WithLazyLoading = (props) => {
        const {
            height, width, lazyLoad, offset, throttle, debounce
        } = props;
        const cleanedProps = _omit(props, ['lazyLoad', 'offset', 'throttle', 'debounce']);
        if (lazyLoad) {
            return (
                <LazyLoad height={height} overflow resize width={width} offset={offset} debounce={debounce} throttle={throttle}>
                    <WrappedComponent {...cleanedProps} />
                </LazyLoad>
            );
        }
        return <WrappedComponent {...props} />;
    };
    WithLazyLoading.propTypes = {
        height: PropTypes.number,
        width: PropTypes.number,
        lazyLoad: PropTypes.bool,
        offset: PropTypes.number,
        throttle: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        debounce: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    };

    WithLazyLoading.defaultProps = {
        debounce: debounceDefault,
        throttle: throttleDefault,
        offset: offsetDefault,
        lazyLoad: true
    };

    return WithLazyLoading;
};

export default withLazyLoading;
