import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import styles from 'src/stylesheets/selectorLabels.scss';
import React from 'react';
import classnames from 'classnames';
import { makeSelectGroupById } from 'src/selectors/groups';
import { makeSelectProfileById } from 'src/selectors/profiles';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';

const ProfileSelectorLabelSingleSelection = ({
    profile, disabled, group, adAccount
}) => {
    let content = 'No profile selected';
    let tooltip = '';

    if (profile || group || adAccount) {
        if (profile || adAccount) {
            const { defaultName, appendix } = profile || adAccount;
            tooltip = appendix ? `${defaultName} ${appendix}` : defaultName;
            content = (
                <span>
                    {defaultName}
                    {
                        // in case the button is disabled do not apply special style for appendix
                        appendix
                        && (
                            <span className={classnames({ [styles.appendix]: !disabled })}>
                                {` ${appendix}`}
                            </span>
                        )
                    }
                </span>
            );
        } else if (group) {
            const { name } = group;
            tooltip = name;
            content = <span>{name}</span>;
        }
    }

    return (
        <div className={styles.wrapper}>
            <DivWithTooltip
              onClick={() => {}}
              tooltip={tooltip}
              referenceWrapperClassName={styles.referenceWrapper}
              className={styles.divWithEllipsis}
            >
                {content}
            </DivWithTooltip>
        </div>
    );
};

ProfileSelectorLabelSingleSelection.propTypes = {
    profileId: PropTypes.string,
    adAccountId: PropTypes.string,
    groupId: PropTypes.string,
    profile: customPropTypes.profile,
    group: customPropTypes.group,
    adAccount: customPropTypes.adAccount,
    disabled: PropTypes.bool
};

ProfileSelectorLabelSingleSelection.defaultProps = {
    disabled: false
};

const makeMapStateToProps = () => {
    const selectGroupById = makeSelectGroupById();
    const selectProfileById = makeSelectProfileById();
    const selectAdAccountById = makeSelectAdAccountById();

    return (state, ownProps) => ({
        group: selectGroupById(state, ownProps.groupId) || undefined,
        profile: selectProfileById(state, ownProps.profileId) || undefined,
        adAccount: selectAdAccountById(state, ownProps.adAccountId) || undefined
    });
};

export default connect(makeMapStateToProps)(ProfileSelectorLabelSingleSelection);
