import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import NameAndCreateTime from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/NameAndCreateTime';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/tableCell.scss';

const TwitterRetweeters = (props) => {
    const {
        cellProps, userName, createTime, cellSetting
    } = props;
    const { showLink } = cellSetting;
    const link = `https://www.twitter.com/${userName}`;
    return (
        <Cell {...cellProps}>
            <div className={styles.head}>
                <NameAndCreateTime name={userName} /> retweeted this tweet at
                <span className={styles.bold}>
                    {` ${createTime} `}
                </span>
                {
                    showLink !== 'none'
                    && (
                    <div className={styles.link}>
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            View profile
                        </a>
                    </div>
                    )
                }
            </div>
        </Cell>
    );
};

TwitterRetweeters.propTypes = {
    userName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

export default TwitterRetweeters;
