import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';
import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { PROFILE_DELETE_SUCCESS, PROFILE_FORCE_DELETE_SUCCESS } from 'src/actions/profiles';
import _omit from 'lodash/omit';
import { removeProfileUseCaseSuccess, removeProfileUseCaseRequest, removeProfileUseCaseError } from 'src/actions/profileUseCases';
import { adAccountUseCaseRemoveError, adAccountUseCaseRemoveSuccess, adAccountUseCaseRemoveRequest } from 'src/actions/adAccountUseCases';
import { createAsyncStatesReducerForAction } from 'src/reducers/utils';

const byAdAccountId = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return Object.assign({}, accountAuthenticationEntities.adAccountUseCases);
        }
        default:
            return state;
    }
};

const byProfileId = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ACCOUNT_ENTITIES_GET_SUCCESS: {
            const { accountAuthenticationEntities } = payload;
            return Object.assign({}, accountAuthenticationEntities.profilesUseCases);
        }
        case PROFILE_DELETE_SUCCESS:
        case PROFILE_FORCE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            return _omit(state, profileIds);
        }
        default:
            return state;
    }
};

const asyncStatesByAction = combineReducers({
    removeUseCase: createAsyncStatesReducerForAction(
        (payload) => `${payload.accountId}_${payload.useCaseId}`,
        [removeProfileUseCaseRequest.type, adAccountUseCaseRemoveRequest.type],
        [removeProfileUseCaseSuccess.type, adAccountUseCaseRemoveSuccess.type, adAccountUseCaseRemoveError.type, removeProfileUseCaseError.type]
    )
});

export const accountUseCases = combineReducers({
    byAdAccountId,
    byProfileId,
    asyncStatesByAction
});
