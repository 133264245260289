import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/flagIcon.scss';

const FlagIcon = (props) => {
    const { countryCode } = props;
    const flagClassName = `flag-${countryCode}`;
    return (
        <div className={classnames(styles.flag, styles[flagClassName])} />
    );
};

FlagIcon.propTypes = {
    countryCode: PropTypes.string.isRequired
};

export default FlagIcon;
