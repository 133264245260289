import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { getMetricTitleAndIcon } from 'src/utils/postSearch';
import IcomoonIcon from 'src/components/IcomoonIcon';
import styles from 'src/stylesheets/views/postTags/postKPI.scss';
import Number from 'src/components/Number';

import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const PostKPI = (props) => {
    const { postKpi } = props;
    const { id, value } = postKpi;
    const { tooltip, icon } = getMetricTitleAndIcon(id);
    return (
        <div className={styles.wrapper}>
            <IcomoonIconWithTooltip icon={icon} tooltip={tooltip} className={styles.icon} />
            <div className={styles.value}>
                <Number value={value} />
            </div>
        </div>
    );
};

PostKPI.propTypes = {
    postKpi: customPropTypes.postKpi.isRequired
};

export default PostKPI;
