import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import ProfilePost from './partials/ProfilePost';

const PostWithAttachment = (props) => {
    const {
        cellProps,
        pageName,
        createTime,
        link,
        internalId,
        message,
        attachmentPreviewPicture,
        cellSetting
    } = props;

    return (
        <Cell {...cellProps}>
            <ProfilePost
              profileName={pageName}
              time={createTime}
              link={link}
              internalId={internalId}
              content={message}
              picture={attachmentPreviewPicture}
              cellSetting={cellSetting}
            />
        </Cell>
    );
};

PostWithAttachment.propTypes = {
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    pageName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    internalId: PropTypes.string,
    message: PropTypes.string,
    attachmentPreviewPicture: PropTypes.string,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

PostWithAttachment.defaultProps = {
    message: '',
    attachmentPreviewPicture: ''
};

export default PostWithAttachment;
