import React from 'react';
import FullyAppliedFiltersContext from 'src/components/context/FullyAppliedFiltersContext';

const withFullyAppliedFilterContextConsumer = (WrappedComponent) => {
    const WithFullyAppliedFilterContextConsumer = (props) => (
        <FullyAppliedFiltersContext.Consumer>
            {
                (fullyAppliedFilters) => <WrappedComponent {...props} fullyAppliedFilters={fullyAppliedFilters} />
            }
        </FullyAppliedFiltersContext.Consumer>
    );

    return WithFullyAppliedFilterContextConsumer;
};

export default withFullyAppliedFilterContextConsumer;
