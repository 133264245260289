import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import '../../icons/style.css';

const IcomoonIcon = (props) => {
    const {
        icon,
        className,
        style,
        onClick,
        onMouseOver,
        onMouseOut,
        onFocus,
        onBlur,
        active,
        activeClassName
    } = props;

    const optionalProps = {};

    if (onClick) {
        optionalProps.onClick = onClick;
    }
    if (onMouseOver) {
        optionalProps.onMouseOver = onMouseOver;
    }
    if (onMouseOut) {
        optionalProps.onMouseOut = onMouseOut;
    }
    if (onFocus) {
        optionalProps.onFocus = onFocus;
    }
    if (onBlur) {
        optionalProps.onBlur = onBlur;
    }
    return (
        <i
          {...optionalProps}
          className={classnames(className, `oiuhb-icon-${icon}`, { [activeClassName]: active })}
          style={style}
        />
    );
};

IcomoonIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    active: PropTypes.bool,
    activeClassName: PropTypes.string,
};

IcomoonIcon.defaultProps = {
    className: '',
    activeClassName: '',
    style: {},
    active: false
};

export default IcomoonIcon;
