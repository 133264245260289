import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PureBodyWrapper extends PureComponent {
    render() {
        const { body } = this.props;
        return (
            <tbody>
                {body}
            </tbody>
        );
    }
}

PureBodyWrapper.propTypes = {
    body: PropTypes.node.isRequired
};

export default PureBodyWrapper;
