import React, { Component } from 'react';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import _omit from 'lodash/omit';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import { SortTypes } from 'src/components/chartViews/fixedDataTable/utils';
import styles from 'src/stylesheets/table/tableHeaderCell.scss';

const getSortIcon = (sortDir) => {
    if (sortDir === SortTypes.DESC) {
        return <IcomoonIcon icon="chevron-small-down" className={styles.icon} />;
    }

    if (sortDir === SortTypes.ASC) {
        return <IcomoonIcon icon="chevron-small-up" className={styles.icon} />;
    }
    return null;
};

class SortableTableHeaderCell extends Component {
    constructor(props) {
        super(props);

        this.handleOnSortChange = this.handleOnSortChange.bind(this);
        this.setInitialOrderSequencePosition = this.setInitialOrderSequencePosition.bind(this);
        this.getOrderSequence = this.getOrderSequence.bind(this);
        this.incrementOrderSequencePosition = this.incrementOrderSequencePosition.bind(this);
        this.getDefaultOrderSequenceByColumnType = this.getDefaultOrderSequenceByColumnType.bind(this);
        this.setInitialOrderSequencePosition();
    }

    handleOnSortChange() {
        const { onSortChange, column, sortDir } = this.props;

        const isSortable = _get(column, 'sortable', true);
        if (!isSortable) {
            return;
        }

        const orderSequence = this.getOrderSequence(column);
        if (sortDir === null) {
            this.orderSequencePos = 0;
        }
        if (onSortChange) {
            onSortChange(column, orderSequence[this.orderSequencePos]);
            this.incrementOrderSequencePosition();
        }
    }

    getOrderSequence() {
        const { column } = this.props;
        return _get(column, 'orderSequence', this.getDefaultOrderSequenceByColumnType());
    }

    getDefaultOrderSequenceByColumnType() {
        const { column } = this.props;
        switch (column.type) {
            case 'integer':
            case 'money':
            case 'decimal':
            case 'percent':
                return [SortTypes.DESC, SortTypes.ASC];

            default:
                return [SortTypes.ASC, SortTypes.DESC];
        }
    }

    setInitialOrderSequencePosition() {
        const { column, sortDir } = this.props;

        let indexPos;
        if (sortDir === null) {
            indexPos = 0;
        } else {
            const index = _indexOf(this.getOrderSequence(column), sortDir);
            indexPos = index === -1 ? 0 : index;
        }

        this.orderSequencePos = indexPos;
        this.incrementOrderSequencePosition();
    }

    incrementOrderSequencePosition() {
        this.orderSequencePos = (this.orderSequencePos + 1) % this.getOrderSequence().length;
    }

    render() {
        const {
            column, sortDir, width, className
        } = this.props;
        const cellProps = _omit(this.props, 'column', 'sortDir', 'onSortChange');
        const padding = 12;
        const isActivelySorted = !!(sortDir);

        const calculatedStylesForCell = {
            width: width - (2 * padding),
        };
        const calculatedStylesForTitle = {
            width: (width - (2 * padding)) - (isActivelySorted ? 20 : 0),
        };

        const sortIcon = sortDir ? getSortIcon(sortDir) : '';

        return (
            <Cell
              {...cellProps}
              style={{ width }}
              className={classnames(styles.headerCell, { [styles.activelySorted]: isActivelySorted }, className)}
              onClick={this.handleOnSortChange}
              headerCell
            >
                <div style={calculatedStylesForCell}>
                    <DivWithTooltip
                      style={calculatedStylesForTitle}
                      tooltip={column.title}
                    >
                        {column.title}
                    </DivWithTooltip>
                    {sortIcon}
                </div>
            </Cell>
        );
    }
}

SortableTableHeaderCell.propTypes = {
    column: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        title: PropTypes.string.isRequired
    }).isRequired,
    width: PropTypes.number.isRequired,
    sortDir: PropTypes.string,
    onSortChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default SortableTableHeaderCell;
