import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import { connect } from 'react-redux';
import { modalShowTwitterUserInteraction } from 'src/actions/overlays';
import NameAndCreateTime from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/NameAndCreateTime';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/tableCell.scss';
import MetricRequestContext from 'src/components/context/MetricRequestContext';
import classnames from 'classnames';

const userNameStyle = {
    marginBottom: '4px'
};

const TwitterUser = (props) => {
    const {
        cellProps, userName, userId, modalShowTwitterUserInteractionAction, cellSetting
    } = props;
    const link = `https://www.twitter.com/${userName}`;
    const { showLink } = cellSetting;
    return (
        <Cell {...cellProps}>
            <div className={styles.head}>
                <div style={userNameStyle}>
                    <NameAndCreateTime name={userName} />
                </div>
                {
                    showLink !== 'none' && (
                        <>
                            <span className={classnames(styles.link, styles.noLinkPadding)}>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    View profile
                                </a>
                            </span>
                            <span className={styles.link}>
                                <MetricRequestContext.Consumer>
                                    {
                                    (serverRequest) => (
                                        <a
                                          href="#"
                                          onClick={(event) => { modalShowTwitterUserInteractionAction(userId, userName, serverRequest.request); event.preventDefault(); }}
                                        >
                                            View interactions
                                        </a>
                                    )
                                }
                                </MetricRequestContext.Consumer>
                            </span>
                        </>
                    )
                }
            </div>
        </Cell>
    );
};

TwitterUser.propTypes = {
    userName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    modalShowTwitterUserInteractionAction: PropTypes.func.isRequired,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

export default connect(null, {
    modalShowTwitterUserInteractionAction: modalShowTwitterUserInteraction
})(TwitterUser);
