import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import { connect } from 'react-redux';
import { modalShowTwitterRetweeters } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';
import MetricRequestContext from 'src/components/context/MetricRequestContext';
import ProfilePost from './partials/ProfilePost';

const TwitterTweet = (props) => {
    const {
        cellProps,
        pageName,
        createTime,
        link,
        internalId,
        message,

        // required for the modal
        profileId,
        isRetweet,
        retweets,
        externalId,
        modalShowTwitterRetweetersAction,
        cellSetting
    } = props;
    const { showLink } = cellSetting;
    const showRetweetersLink = ((isRetweet === '0' || isRetweet === '') && retweets > 0 && profileId && externalId);
    const optionalProps = {};
    if (showRetweetersLink && showLink !== 'none') {
        optionalProps.additionalCustomLink = (
            <MetricRequestContext.Consumer>
                {
                    (serverRequest) => (
                        <a
                          href="#"
                          onClick={(event) => {
                              modalShowTwitterRetweetersAction(externalId, profileId, pageName, serverRequest.request);
                              event.preventDefault();
                          }}
                        >
                            View retweeters
                        </a>
                    )
                }
            </MetricRequestContext.Consumer>
        );
    }

    return (
        <Cell {...cellProps}>
            <ProfilePost
              profileName={pageName}
              time={createTime}
              link={link}
              internalId={internalId}
              linkText="View Tweet"
              content={message}
              cellSetting={cellSetting}
              {...optionalProps}
            />
        </Cell>
    );
};

TwitterTweet.propTypes = {
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    pageName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    internalId: PropTypes.string,
    message: PropTypes.string.isRequired,

    // required for the modal
    isRetweet: PropTypes.string,
    retweets: PropTypes.number.isRequired,
    profileId: PropTypes.number.isRequired,
    externalId: PropTypes.string.isRequired,
    modalShowTwitterRetweetersAction: PropTypes.func.isRequired,
    filterContext: PropTypes.string.isRequired,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

TwitterTweet.defaultProps = {
    isRetweet: ''
};

export default withFilterContextConsumer(connect(null, {
    modalShowTwitterRetweetersAction: modalShowTwitterRetweeters
})(TwitterTweet));
