import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAccountOptions } from 'src/selectors/loggedInUser';

const addThousandSeparators = (value, thousandSeparator) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

export const numberString = (value, numberFormat) => {
    const { thousandSeparator, decimalSeparator } = numberFormat;

    if (value.indexOf('.') === -1) {
        // if there is no decimals, just add thousand separators
        return addThousandSeparators(value, thousandSeparator);
    }

    const valueParts = value.split('.');
    const intPart = valueParts[0];
    const decPart = valueParts[1];
    const intPartWithThousandSeparator = addThousandSeparators(intPart, thousandSeparator);
    return `${intPartWithThousandSeparator}${decimalSeparator}${decPart}`;
};

/**
 * In contrast to Number this component is made to handle a numeric string (e.g. 10.120)
 * and keep it as is (no manipulation of e.g. numbers behind the decimal point), just localize it
 */
const NumberString = (props) => {
    const { value, accountOptions } = props;
    return (
        <span>{numberString(value, accountOptions.numberFormat)}</span>
    );
};

NumberString.propTypes = {
    value: PropTypes.string.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(NumberString);
