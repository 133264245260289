import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/loadingIndicators/verticallyHorizontallyCentered.scss';

const VerticallyHorizontallyCentered = ({ text }) => (
    <div className={styles.wrapper}>
        <div className={styles.content}>
            <div className={styles.text}>
                {text}
            </div>
        </div>
    </div>
);

VerticallyHorizontallyCentered.propTypes = {
    text: PropTypes.string
};

VerticallyHorizontallyCentered.defaultProps = {
    text: 'Loading...'
};

export default VerticallyHorizontallyCentered;
