import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import { convertSecondsForView } from './utils';

const ResponseTime = (props) => {
    const { timeToRespond, respTime, cellProps } = props;
    let valueToShow = 'n/a';

    if (timeToRespond !== null && respTime !== null) {
        valueToShow = convertSecondsForView(parseInt(timeToRespond, 10));
        valueToShow = `${valueToShow} (${respTime})`;
    }
    return (
        <Cell {...cellProps}>
            <span>
                {valueToShow}
            </span>
        </Cell>
    );
};

ResponseTime.propTypes = {
    timeToRespond: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    respTime: PropTypes.string,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

ResponseTime.defaultProps = {
    timeToRespond: null,
    respTime: null
};

export default ResponseTime;
