import PropTypes from 'prop-types';
import React from 'react';
import NameAndCreateTime from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/NameAndCreateTime';
import Post from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/Post';
import * as customPropTypes from 'src/customPropTypes';

const generateContentItemsFromTitleAndDescription = (title, description) => {
    // If both title and description are available
    if (title && description) {
        // It is common that title and description are set to the same value, so we explicitly check for this case
        const titleWithoutLineBreaks = title.replace(/\r?\n|\r/g, ' ').replace(/\s{2,}/g, ' ');
        const descriptionWithoutLineBreaks = description.replace(/\r?\n|\r/g, ' ').replace(/\s{2,}/g, ' ');

        // If the title is a prefix of the description (this is e.g. the case for Facebook videos if you do not provide a
        // title), we prefer the description over the title, otherwise the title wins
        if (descriptionWithoutLineBreaks.startsWith(titleWithoutLineBreaks)) {
            return [{
                id: 'description',
                content: description
            }];
        }

        return [{
            id: 'title',
            content: title
        }];
    }

    // If only a title is available
    if (title && !description) {
        return [{
            id: 'title',
            content: title
        }];
    }

    // If only a description is available
    if (!title && description) {
        return [{
            id: 'description',
            content: description
        }];
    }

    return [];
};

const ProfileVideo = (props) => {
    const {
        profileName,
        time,
        title,
        description,
        link,
        internalId,
        linkText,
        picture,
        cellSetting
    } = props;

    const contentItems = generateContentItemsFromTitleAndDescription(title, description);

    return (
        <Post
          headline={<NameAndCreateTime name={profileName} createTime={time} />}
          link={link}
          linkText={linkText}
          picture={picture}
          contentItems={contentItems}
          internalId={internalId}
          cellSetting={cellSetting}
        />
    );
};

ProfileVideo.propTypes = {
    profileName: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    internalId: PropTypes.string,
    linkText: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    picture: PropTypes.string,
    cellSetting: customPropTypes.tableCellSetting.isRequired
};

ProfileVideo.defaultProps = {
    linkText: 'View video',
    title: '',
    description: '',
    picture: ''
};

export default ProfileVideo;
