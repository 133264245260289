import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';

const withAsyncStateHandling = (DataComponent, LoadingComponent = null, ErrorComponent = null) => {
    const WithAsyncStateHandling = (props) => {
        const { data, error, loading } = props;
        if (loading) {
            if (LoadingComponent) {
                return <LoadingComponent {...props} />;
            }
            return <div>Loading</div>;
        } if (error !== null) {
            if (ErrorComponent) {
                return <ErrorComponent error={error} {...props} />;
            }
            return <div>Error: {error}</div>;
        }
        return <DataComponent {...props} data={data} />;
    };

    WithAsyncStateHandling.propTypes = {
        data: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
        error: customPropTypes.dataLoadingError,
        loading: PropTypes.bool.isRequired
    };

    const dataComponentName = DataComponent.displayName || DataComponent.name || 'Component';
    const loadingComponentName = LoadingComponent ? (LoadingComponent.displayName || LoadingComponent.name || 'Component') : 'null';
    const errorComponentName = ErrorComponent ? (ErrorComponent.displayName || ErrorComponent.name || 'Component') : 'null';
    WithAsyncStateHandling.displayName = `withAsyncStateHandling(${dataComponentName}, ${loadingComponentName}, ${errorComponentName})`;

    return WithAsyncStateHandling;
};

export default withAsyncStateHandling;
