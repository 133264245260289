import { Modal } from 'react-overlays';

class ModalAndPopoverManager extends Modal.Manager {
    constructor(params) {
        super(params);
        this.parentIsTopModal = this.isTopModal.bind(this);
        this.isTopModal = this.isTopModalOrPopoverOpen.bind(this);
        this.popovers = [];
        this.addPopover = this.addPopover.bind(this);
        this.isPopoverOnTop = this.isPopoverOnTop.bind(this);
    }

    isPopoverOnTop(popover) {
        if (this.popovers[this.popovers.length - 1] === popover) {
            return true;
        }
        return false;
    }

    addPopover(popover) {
        const inArray = this.popovers.indexOf(popover);
        if (inArray !== -1) {
            return false;
        }
        this.popovers.push(popover);
        return true;
    }

    removePopOver(popover) {
        this.popOverCount -= 1;
        const popoverIdx = this.popovers.indexOf(popover);
        if (popoverIdx === -1) {
            return false;
        }
        this.popovers.splice(popoverIdx, 1);
        return true;
    }

    isTopModalOrPopoverOpen(modal) {
        if (this.popovers.length > 0) {
            return false;
        }

        return this.parentIsTopModal(modal);
    }
}

const modalManager = new ModalAndPopoverManager();

export default modalManager;
