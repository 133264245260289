import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';

const Boolean = (props) => {
    const { value, cellProps } = props;
    let valueToShow = 'n/a';
    if (value !== null) {
        valueToShow = (value === true || value === '1' || value === 1) ? 'Yes' : 'No';
    }
    return (
        <Cell {...cellProps}>
            {valueToShow}
        </Cell>
    );
};

Boolean.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

Boolean.defaultProps = {
    value: null
};

export default Boolean;
