import _isArray from 'lodash/isArray';

export const SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC'
};

export const PAGE_LENGTH = 25;

export const getColumnsToRender = (columns, hideColumns) => {
    if (_isArray(hideColumns)) {
        const columnsToRender = [];
        columns.forEach((column) => {
            if (hideColumns.indexOf(column.id) < 0) {
                columnsToRender.push(column);
            }
        });
        return columnsToRender;
    }
    return columns;
};

export const findColumnById = (columnId, columns) => {
    const matchingColumn = columns.find((column) => column.id === columnId);
    if (!matchingColumn) {
        return false;
    }
    return matchingColumn;
};

const parseValueByType = (value, type) => {
    if (value === null) {
        return null;
    }
    if (type === 'integer') {
        return parseInt(value, 10);
    }
    if (type === 'percent' || type === 'decimal') {
        // strings are valid inputs as well, as currently we do formatting e.g. with PRINTF in QQL and just pass this through
        if (typeof value === 'string') {
            return value;
        }
        // in all other cases (!= string) we parse it as float
        return parseFloat(value);
    }
    return value;
};

export const calculateSortIndexes = (unsortedData, column, sortDir) => {
    const defaultSortIndexes = unsortedData.map((result, index) => index);
    const sortIndexes = defaultSortIndexes.slice();
    sortIndexes.sort((indexA, indexB) => {
        let valueA = parseValueByType(unsortedData[indexA][column.id], column.type);
        let valueB = parseValueByType(unsortedData[indexB][column.id], column.type);

        const columnCanHaveNumericString = (column.type === 'decimal' || column.type === 'percent');
        if (columnCanHaveNumericString && (typeof valueA === 'string' || typeof valueB === 'string')) {
            valueA = parseFloat(valueA);
            valueB = parseFloat(valueB);

            if (window.Number.isNaN(valueA)) {
                valueA = -999999999;
            }
            if (window.Number.isNaN(valueB)) {
                valueB = -999999999;
            }
        } else {
            if (valueA === null) {
                valueA = -999999999;
            }
            if (valueB === null) {
                valueB = -999999999;
            }
        }

        let sortVal = 0;
        if (valueA > valueB) {
            sortVal = 1;
        }
        if (valueA < valueB) {
            sortVal = -1;
        }
        if (sortVal !== 0 && sortDir === SortTypes.DESC) {
            sortVal *= -1;
        }
        return sortVal;
    });

    return sortIndexes;
};
