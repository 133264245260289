import _range from 'lodash/range';
import classnames from 'classnames';
import React from 'react';
import styles from 'src/stylesheets/table/table.scss';
import TableCell from 'src/components/chartViews/fixedDataTable/TableCell';
import FixedDataTableWidthHelper from './FixedDataTableWidthHelper';

const TableBodyRows = (props) => {
    const {
        columns, rowsCount, sortBy, sortDir, results
    } = props;

    if (rowsCount < 1) {
        return (
            <tr>
                <td className={classnames(styles.noData, styles.centerAlign)} colSpan={columns.length}>Empty results</td>
            </tr>
        );
    }

    return _range(rowsCount).map((index) => {
        const columnsToRender = columns.map((column) => {
            const columnsSortDir = column.id === sortBy ? sortDir : null;
            const align = FixedDataTableWidthHelper.getAlignment(column.type);
            return (
                <TableCell
                  data={results}
                  sortDir={columnsSortDir}
                  column={column}
                  columnKey={column.id}
                  rowIndex={index}
                  className={classnames(styles.wordwrap, styles[`${align}Align`])}
                  key={`td-${column.id}-${index}`}
                />
            );
        });

        const lastRow = (index === rowsCount - 1);

        return (
            <tr
              className={classnames(styles.tableRow, { [styles.lastTableRow]: lastRow })}
              key={`tr-${index}`}
            >
                {columnsToRender}
            </tr>
        );
    });
};

export default TableBodyRows;
