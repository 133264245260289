class SortedResultsWrapper {
    constructor(indexMap, results) {
        this.indexMap = indexMap;
        this.results = results;
    }

    getSize() {
        return this.indexMap.length;
    }

    getObjectAt(index) {
        return this.results[this.indexMap[index]];
    }

    getSlice(start, end) {
        return new SortedResultsWrapper(this.indexMap.slice(start, end), this.results);
    }
}

export default SortedResultsWrapper;
