import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/metricNameHeaderWithIcons.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const MetricNameHeaderWithIcons = (props) => {
    const {
        name,
        platformType,
        isBasedOnPrivateStatistics,
        isCustomMetric,
        size,
        centre,
        tooltipPlacement,
    } = props;

    return (
        <div
          className={classnames(
              styles.wrapper,
              { [styles.medium]: size === 'medium' },
              { [styles.small]: size === 'small' },
              { [styles.large]: size === 'large' },
              { [styles.centre]: centre }
          )}
        >
            {
                platformType
                && (
                <NetworkIcon
                  platformType={platformType}
                  className={styles.networkIcon}
                />
                )
            }
            <div className={styles.ellipsisWrapper}>
                <DivWithTooltip
                  tooltip={name}
                  className={classnames(styles.metricName, 'metricNameForHover')}
                >
                    {name}
                </DivWithTooltip>
            </div>
            {
                isBasedOnPrivateStatistics
                && (
                <IcomoonIconWithTooltip
                  icon="key"
                  className={classnames(styles.icon, styles.privateStatisticsIcon)}
                  tooltip="Private statistics analysis"
                  tooltipPlacement={tooltipPlacement}
                />
                )
            }
            {
                isCustomMetric
                && (
                <IcomoonIconWithTooltip
                  icon="metric-editor"
                  className={classnames(styles.icon, styles.customMetricIcon)}
                  tooltip="Custom metric"
                  tooltipPlacement={tooltipPlacement}
                />
                )
            }
        </div>
    );
};

MetricNameHeaderWithIcons.propTypes = {
    name: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    isBasedOnPrivateStatistics: PropTypes.bool,
    isCustomMetric: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    centre: PropTypes.bool,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

MetricNameHeaderWithIcons.defaultProps = {
    isBasedOnPrivateStatistics: false,
    isCustomMetric: false,
    size: 'small',
    centre: false,
    tooltipPlacement: 'top'
};

export default MetricNameHeaderWithIcons;
