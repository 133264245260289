import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import sizeMe from 'react-sizeme';

// This one needs always needs a parent that has display: flex. If you also want to provide display flex to children, set displayFlex to true
const withFlexDimensionProvider = (WrappedComponent, monitorWidth = true, monitorHeight = false, refreshRate = 300, noPlaceholder = false) => {
    const WithFlexDimensionProvider = (props) => {
        const { size, className } = props;
        const { width, height } = size;
        const propsWithoutSize = _omit(props, ['size', 'className']);

        if (monitorWidth) {
            propsWithoutSize.width = width;
        }

        if (monitorHeight) {
            propsWithoutSize.height = height;
        }
        return (
            <div className={className}>
                <WrappedComponent {...propsWithoutSize} />
            </div>
        );
    };
    WithFlexDimensionProvider.propTypes = {
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number
        }).isRequired,
        className: PropTypes.string,
    };

    WithFlexDimensionProvider.defaultProps = {
        className: '',
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithFlexDimensionProvider.displayName = `withFlexDimensionProvider(${wrappedComponentName})`;
    return sizeMe({
        monitorWidth, monitorHeight, refreshRate, noPlaceholder
    })(WithFlexDimensionProvider);
};

export default withFlexDimensionProvider;
