import React from 'react';
import FilterContext from 'src/components/context/FilterContext';

const withFilterContextConsumer = (WrappedComponent) => {
    const WithFilterContextConsumer = (props) => (
        <FilterContext.Consumer>
            {
                (filterContext) => <WrappedComponent {...props} filterContext={filterContext} />
            }
        </FilterContext.Consumer>
    );

    return WithFilterContextConsumer;
};

export default withFilterContextConsumer;
