import { getIsSentrySetup, captureException } from 'src/utils/sentry';

// eslint-disable-next-line no-console
const logger = console.error;

export const reportError = (error, extras = {}) => {
    // ignore serverErrors
    if (error.serverError !== true) {
        if (getIsSentrySetup()) {
            logger('Reporting error to Sentry:', error, extras);
            return captureException(error, extras);
        }
        logger(error, extras);
        return null;
    }
    return null;
};
