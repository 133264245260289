import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Shadow from './Shadow';

class ShadowHandler extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            top: false,
            right: false,
            bottom: false,
            left: false
        };
    }

    setShadowValues(top, right, bottom, left) {
        this.setState({
            top,
            right,
            bottom,
            left
        });
    }

    render() {
        const {
            top,
            right,
            bottom,
            left
        } = this.state;

        const { hasSummaryLine } = this.props;

        return (
            <div>
                <Shadow active={top} placement="top" />
                <Shadow active={right} placement="right" />
                <Shadow active={bottom} hasSummaryLine={hasSummaryLine} placement="bottom" />
                <Shadow active={left} placement="left" />
            </div>
        );
    }
}

ShadowHandler.propTypes = {
    hasSummaryLine: PropTypes.bool
};

ShadowHandler.defaultProps = {
    hasSummaryLine: false
};

export default ShadowHandler;
