import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';

const String = ({ value, cellProps }) => {
    const valueToShow = (value !== null) ? value.toString() : 'n/a';
    return (
        <Cell {...cellProps}>
            {valueToShow}
        </Cell>
    );
};

String.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired
};

String.defaultProps = {
    value: null
};

export default String;
