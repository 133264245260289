import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/table/table.scss';

class TableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            left: 0
        };
    }

    setOffset(offset) {
        const { left } = this.state;
        if (left !== offset) {
            this.setState({ left: offset });
        }
    }

    render() {
        const { left } = this.state;
        const { renderWidth, header } = this.props;
        return (
            <div className={styles.tableHeader} style={{ left, width: renderWidth }}>
                <table className={styles.table}>
                    {header}
                </table>
            </div>
        );
    }
}

TableHeader.propTypes = {
    renderWidth: PropTypes.number.isRequired,
    header: PropTypes.node.isRequired
};

export default TableHeader;
