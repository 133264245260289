import PropTypes from 'prop-types';
import React from 'react';

const HighchartError = ({ message }) => <div>{message}</div>;

HighchartError.propTypes = {
    message: PropTypes.string
};

HighchartError.defaultProps = {
    message: 'Unexpected error'
};

export default HighchartError;
