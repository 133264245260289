import * as cells from 'src/components/chartViews/fixedDataTable/tableCellRenderers/index';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _upperFirst from 'lodash/upperFirst';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ResultsWrapper from 'src/components/chartViews/fixedDataTable/ResultsWrapper';
import SortedResultsWrapper from 'src/components/chartViews/fixedDataTable/SortedResultsWrapper';
import styles from 'src/stylesheets/table/tableCell.scss';
import tableCells from 'phpConfig/tableCells.json';
import withTableCellSettingContextConsumer from 'src/components/chartViews/fixedDataTable/withTableCellSettingContextConsumer';

const getCustomPropsByType = (columnType, value, dataForRow) => {
    const column = tableCells[columnType];
    if (column) {
        const { fields, propName } = column;
        const fieldsToSend = {
            [propName]: value
        };

        Object.keys(fields).forEach((fieldId) => {
            const { propName: propNameFromField } = fields[fieldId];
            fieldsToSend[propNameFromField] = dataForRow[fieldId];
        });
        return fieldsToSend;
    }

    return {
        value
    };
};

const createCellByType = (columnType, value, dataForRow, cellProps) => {
    const sharedProps = {
        cellProps
    };
    const customProps = getCustomPropsByType(columnType, value, dataForRow);
    const props = Object.assign({}, sharedProps, customProps);

    const TypedCell = _get(cells, _upperFirst(columnType), 'String');
    const TypedCellWithTableCellSettings = withTableCellSettingContextConsumer(TypedCell);
    return <TypedCellWithTableCellSettings {...props} />;
};

const TableCell = (props) => {
    const {
        column,
        rowIndex,
        data,
        sortDir,
        className
    } = props;
    const columnType = _get(column, 'type', 'string');
    const dataForRow = data.getObjectAt(rowIndex);
    const value = dataForRow[column.id];
    const isActivelySorted = !!(sortDir);
    const cellProps = _omit(props, ['column', 'data', 'rowIndex', 'sortDir']);
    // handle if a column is actively sorted by centrally
    cellProps.className = classnames({ [styles.activelySorted]: isActivelySorted }, className, styles.tableCell);
    return createCellByType(columnType, value, dataForRow, cellProps);
};

TableCell.propTypes = {
    column: customPropTypes.tableCellColumn.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.instanceOf(SortedResultsWrapper),
        PropTypes.instanceOf(ResultsWrapper)
    ]).isRequired,
    rowIndex: PropTypes.number,
    sortDir: PropTypes.string
};

export default TableCell;
