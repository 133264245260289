import NetworkIcon from 'src/components/NetworkIcon';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import React from 'react';
import noImage from 'src/resources/illustrations/no_image.png';
import styles from 'src/stylesheets/views/profiles/profileInfoListItem.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Highlighter } from 'react-bootstrap-typeahead';

const AccountInfoListItem = ({
    thumbnail, name, appendix, platformType, highlight, showThumbnail
}) => (
    <div className={styles.wrapper}>
        <div className={styles.left}>
            {
                showThumbnail && (
                    <>
                        {
                            thumbnail
                            && <div className={styles.profilePicture} style={{ backgroundImage: `url(${thumbnail}), url(${noImage})` }} />
                        }
                        {
                            !thumbnail
                            && <div className={styles.profilePicture} style={{ backgroundImage: `url(${noImage})` }} />
                        }
                    </>
                )
            }
            <div className={styles.ellipsisWrapper}>
                <DivWithTooltip
                  tooltip={name}
                  className={classnames(styles.name, { [styles.withAppendix]: !!appendix, [styles.withoutThumbnail]: !showThumbnail }, 'profileNameForHover')}
                >
                    <span dir="auto">
                        <Highlighter search={highlight}>{name}</Highlighter>
                    </span>
                </DivWithTooltip>
                {
                        appendix
                        && (
                            <DivWithTooltip
                              tooltip={appendix}
                              className={classnames(styles.appendix, { [styles.withoutThumbnail]: !showThumbnail })}
                            >
                                <Highlighter search={highlight}>{appendix}</Highlighter>
                            </DivWithTooltip>
                        )
                    }
            </div>
        </div>
        <div className={styles.right}>
            <NetworkIcon
              platformType={platformType}
              className={styles.networkIcon}
            />
        </div>
    </div>
);

AccountInfoListItem.propTypes = {
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    platformType: PropTypes.string.isRequired,
    appendix: PropTypes.string,
    highlight: PropTypes.string,
    showThumbnail: PropTypes.bool
};

AccountInfoListItem.defaultProps = {
    highlight: '',
    appendix: '',
    thumbnail: '',
    showThumbnail: true
};

export default AccountInfoListItem;
