import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import NameAndCreateTime from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/NameAndCreateTime';
import ParsedExternalLinkString from 'src/components/chartViews/fixedDataTable/tableCellRenderers/ParsedExternalLinkString';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/tableCell.scss';
import InternalPostDetailLink from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/InternalPostDetailLink';

const TweeterOfPages = (props) => {
    const {
        userName, createTime, profileName, link, message, cellProps, internalId, cellSetting
    } = props;
    const { showLink } = cellSetting;
    return (
        <Cell {...cellProps}>
            <div className={styles.head}>
                <NameAndCreateTime name={`${userName} to ${profileName}`} createTime={createTime} />
                {
                    showLink === 'none'
                    && (
                    <span className={styles.link}>
                        {
                            (internalId && showLink === 'in-app')
                            && <InternalPostDetailLink postId={internalId} />
                        }
                        {
                            (link && (showLink === 'public' || !internalId))
                            && (
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    View tweet
                                </a>
                            )
                        }
                    </span>
                    )
                }
            </div>
            <div className={styles.body}>
                <ParsedExternalLinkString text={message} />
            </div>
        </Cell>
    );
};

TweeterOfPages.propTypes = {
    createTime: PropTypes.string.isRequired,
    profileName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    cellSetting: customPropTypes.tableCellSetting.isRequired,
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    internalId: PropTypes.string,
};

export default TweeterOfPages;
