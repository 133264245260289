import classnames from 'classnames';
import { getIconForPlatformType } from 'src/utils/string';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/networkColors.scss';
import MultiPathIcomoonIcon from 'src/components/MultiPathIcomoonIcon';

const multiColorNetwork = {
    snapchatShow: 2
};

const NetworkIcon = (props) => {
    const {
        platformType, className, disabled, isDeprecated
    } = props;

    const sharedProps = {
        icon: getIconForPlatformType(platformType, isDeprecated),
        className: classnames(styles[`${platformType}Color`], className, { [styles.disabled]: disabled })
    };

    // For tiktok and snapchatShow we use a special multi color icon
    if (platformType === 'snapchatShow' || platformType === 'threads') {
        return (
            <MultiPathIcomoonIcon {...sharedProps} numberOfPaths={multiColorNetwork[platformType]} />
        );
    }

    return (
        <IcomoonIcon {...sharedProps} />
    );
};

NetworkIcon.propTypes = {
    platformType: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isDeprecated: PropTypes.bool
};

NetworkIcon.defaultProps = {
    className: '',
    disabled: false,
    isDeprecated: false
};

export default NetworkIcon;
