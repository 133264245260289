import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/divWithTooltip.scss';
import withToolTip from 'src/components/withToolTip';

class DivWithTooltip extends Component {
    constructor(props) {
        super(props);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOver(proxy, event) {
        if (this.divWithTooltip.scrollWidth > this.divWithTooltip.offsetWidth) {
            const { onMouseOver } = this.props;
            onMouseOver(proxy, event);
        }
    }

    handleMouseOut(proxy, event) {
        const { onMouseOut } = this.props;
        onMouseOut(proxy, event);
    }

    render() {
        const { className, children } = this.props;
        const combinedClassName = className ? classnames(className, styles.ellipsis) : styles.ellipsis;
        return (
            <div
              {...this.props}
              className={combinedClassName}
              ref={(node) => { this.divWithTooltip = node; }}
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
            >
                {children}
            </div>
        );
    }
}

DivWithTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default withToolTip(DivWithTooltip);
