class ResultsWrapper {
    constructor(results) {
        this.results = results;
    }

    getSize() {
        return this.results.length;
    }

    getObjectAt(index) {
        return this.results[index];
    }
}

export default ResultsWrapper;
