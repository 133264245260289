import PropTypes from 'prop-types';
import React from 'react';
import NameAndCreateTime from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/NameAndCreateTime';
import Post from 'src/components/chartViews/fixedDataTable/tableCellRenderers/partials/Post';
import * as customPropTypes from 'src/customPropTypes';

const UserPost = (props) => {
    const {
        profileName,
        userName,
        interactionText,
        interactionObject,
        time,
        content,
        link,
        picture,
        cellSetting
    } = props;

    const headline = (
        <>
            <NameAndCreateTime name={profileName} /> {interactionText} by <NameAndCreateTime name={userName} createTime={time} />
        </>
    );

    const contentItems = [];
    if (content) {
        contentItems.push({
            id: 'message',
            content
        });
    }

    return (
        <Post
          headline={headline}
          link={link}
          linkText={`View ${interactionObject}`}
          picture={picture}
          contentItems={contentItems}
          cellSetting={cellSetting}
        />
    );
};

UserPost.propTypes = {
    profileName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    interactionText: PropTypes.string.isRequired,
    interactionObject: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    content: PropTypes.string,
    picture: PropTypes.string,
    cellSetting: customPropTypes.tableCellSetting
};

UserPost.defaultProps = {
    content: '',
    picture: '',
    cellSetting: {}
};

export default UserPost;
