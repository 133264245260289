import { usePopper } from 'react-popper';
import React, { Component } from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import contains from 'dom-helpers/contains';
import { createChainedFunction } from 'src/components/withPopover';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styles from 'src/stylesheets/popper.scss';
import tooltipStyles from 'src/stylesheets/tooltip.scss';

const options = {
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 10],
            }
        },
        {
            name: 'preventOverflow',
            options: {
                rootBoundary: 'document',
            }
        },
        {
            name: 'flip'
        },
        {
            name: 'arrow'
        }
    ]
};

const PopperComponent = (props) => {
    const {
        show, renderFunction, referenceWrapperClassName, tooltipPlacement, tooltip
    } = props;

    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles: popperstyles, attributes } = usePopper(referenceElement, popperElement, Object.assign({}, options, { placement: tooltipPlacement }));
    return (
        <>
            <div ref={setReferenceElement} className={referenceWrapperClassName}>
                { renderFunction() }
            </div>
            {
                show && ReactDOM.createPortal(
                    <div
                      className={classnames(styles.popper, tooltipStyles.tooltip)}
                      ref={setPopperElement}
                      style={popperstyles.popper}
                      {...attributes.popper}
                    >
                        <div className={tooltipStyles.tooltipArrow} data-popper-arrow style={popperstyles.arrow} />
                        <div className={tooltipStyles.tooltipInner}>{tooltip}</div>
                    </div>,
                    document.body
                )
            }
        </>
    );
};

PopperComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    renderFunction: PropTypes.func.isRequired,
    referenceWrapperClassName: PropTypes.string,
    tooltipPlacement: PropTypes.string.isRequired,
    tooltip: PropTypes.node.isRequired
};

// Simple implementation of mouseEnter and mouseLeave.
// React's built version is broken: https://github.com/facebook/react/issues/4251
// for cases when the trigger is disabled and mouseOut/Over can cause flicker
// moving from one child element to another.
const handleMouseOverOut = (handler, e) => {
    const target = e.currentTarget;
    const related = e.relatedTarget || e.nativeEvent.toElement;

    if (!related || (related !== target && !contains(target, related))) {
        handler(e);
    }
};

const withTooltip = (WrappedComponent) => {
    class WithTooltip extends Component {
        constructor(props) {
            super(props);
            this.state = { show: false };

            this.handleToggle = this.handleToggle.bind(this);
            this.hideAndUnregister = this.hideAndUnregister.bind(this);
            this.hide = this.hide.bind(this);
            this.show = this.show.bind(this);
            this.handleDelayedShow = this.handleDelayedShow.bind(this);
            this.handleDelayedHide = this.handleDelayedHide.bind(this);

            this.handleMouseOver = (e) => (
                handleMouseOverOut(this.handleDelayedShow, e)
            );
            this.handleMouseOut = (e) => (
                handleMouseOverOut(this.handleDelayedHide, e)
            );
        }

        componentWillUnmount() {
            if (this.hoverShowDelayTimer != null) {
                clearTimeout(this.hoverShowDelayTimer);
                this.hoverShowDelayTimer = null;
            }
        }

        handleDelayedShow(e) {
            // Don't show if a button is clicked (drag and drop for instance)
            if (e.nativeEvent.buttons > 0) {
                return;
            }

            if (this.hoverHideDelayTimer != null) {
                clearTimeout(this.hoverHideDelayTimer);
                this.hoverHideDelayTimer = null;
                return;
            }
            const { show } = this.state;
            if (show || this.hoverShowDelayTimer != null) {
                return;
            }
            const { delayTooltipShow, delayTooltip } = this.props;
            const delayIfActive = delayTooltipShow != null
                ? delayTooltipShow : delayTooltip;

            if (!delayIfActive) {
                this.show();
                return;
            }

            this.hoverShowDelayTimer = setTimeout(() => {
                this.hoverShowDelayTimer = null;
                this.show();
            }, delayIfActive);
        }

        handleDelayedHide() {
            if (this.hoverShowDelayTimer != null) {
                clearTimeout(this.hoverShowDelayTimer);
                this.hoverShowDelayTimer = null;
                return;
            }
            const { show } = this.state;

            if (!show || this.hoverHideDelayTimer != null) {
                return;
            }

            const { delayTooltipHide, delayTooltip } = this.props;
            const delayIfActive = delayTooltipHide != null
                ? delayTooltipHide : delayTooltip;

            if (!delayIfActive) {
                this.hide();
                return;
            }

            this.hoverHideDelayTimer = setTimeout(() => {
                this.hoverHideDelayTimer = null;
                this.hide();
            }, delayIfActive);
        }

        handleToggle() {
            const { show } = this.state;
            if (show) {
                this.hide();
            } else {
                this.show();
            }
        }

        show() {
            this.setState({ show: true });
        }

        hideAndUnregister() {
            if (this.hoverShowDelayTimer != null) {
                clearTimeout(this.hoverShowDelayTimer);
                this.hoverShowDelayTimer = null;
            }
            const { show } = this.state;
            if (show) {
                this.hide();
            }
        }

        hide() {
            this.setState({ show: false });
        }

        render() {
            const {
                tooltipPlacement,
                tooltip,
                referenceWrapperClassName,
                disabled
            } = this.props;

            const targetProps = _omit(this.props, [
                'tooltip',
                'tooltipPlacement',
                'delayTooltipShow',
                'delayTooltipHide',
                'delayTooltip',
                'referenceWrapperClassName'
            ]);

            targetProps.onMouseOver = createChainedFunction(targetProps.onMouseOver, this.handleMouseOver);
            targetProps.onMouseOut = createChainedFunction(targetProps.onMouseOut, this.handleMouseOut);
            if (targetProps.onClick) {
                targetProps.onClick = createChainedFunction(this.hideAndUnregister, targetProps.onClick);
            }

            const { show } = this.state;
            return (
                <PopperComponent
                  show={show}
                  renderFunction={() => {
                      if (!disabled) {
                          return <WrappedComponent {...targetProps} />;
                      }
                      return (
                          <div className={tooltipStyles.disabledContainer}>
                              <WrappedComponent {...targetProps} />
                              <div
                                className={tooltipStyles.disabledActor}
                                onMouseOver={this.handleMouseOver}
                                onMouseOut={this.handleMouseOut}
                              />
                          </div>
                      );
                  }}
                  referenceWrapperClassName={referenceWrapperClassName}
                  tooltip={tooltip}
                  tooltipPlacement={tooltipPlacement}
                />
            );
        }
    }

    WithTooltip.propTypes = {
        tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
        tooltipPlacement: PropTypes.string,
        delayTooltipShow: PropTypes.number,
        delayTooltipHide: PropTypes.number,
        delayTooltip: PropTypes.number,
        referenceWrapperClassName: PropTypes.string,
        disabled: PropTypes.bool // this is not a disabled for the tooltip itself, but a pass down prop for the child
        // if a button is disabled it does not support the mouse events, thus we wrap it into a div
    };
    WithTooltip.defaultProps = {
        tooltipPlacement: 'top',
        delayTooltipShow: 400,
        referenceWrapperClassName: ''
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithTooltip.displayName = `withTooltip(${wrappedComponentName})`;

    return WithTooltip;
};

export default withTooltip;
