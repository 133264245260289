import React from 'react';
import PropTypes from 'prop-types';
import AdAccountCampaignName from 'src/components/filter/AdAccountCampaignName';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectAdAccountCampaignById } from 'src/selectors/adAccountCampaigns';
import styles from 'src/stylesheets/filter.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { connect } from 'react-redux';
import CommaSeparatedValueList from 'src/components/filter/CommaSeparatedValueList';
import FilterItem from 'src/components/filter/FilterItem';

const makeMapStateToPropsForPostTagName = () => {
    const selectAdAccountCampaignById = makeSelectAdAccountCampaignById();
    return (state, ownProps) => {
        const adAccountCampaign = selectAdAccountCampaignById(state, ownProps.adAccountCampaignId);
        return {
            name: adAccountCampaign ? adAccountCampaign.name : 'Invalid Ad Campaign'
        };
    };
};

const ConnectedAdAccountCampaignName = connect(makeMapStateToPropsForPostTagName)(AdAccountCampaignName);

const AdCampaignFilterSummary = (props) => {
    const {
        state,
        input,
        hasCustomBinding
    } = props;

    const {
        values,
        conjunction,
    } = input;

    let content = '';
    const adCampaignNames = values.map((adAccountCampaignId) => <ConnectedAdAccountCampaignName key={adAccountCampaignId} adAccountCampaignId={adAccountCampaignId} />);
    content = <CommaSeparatedValueList items={adCampaignNames} conjunctionWord={conjunction === 'all' ? 'and' : 'or'} />;

    return (
        <FilterItem
          state={state}
          hasCustomBinding={hasCustomBinding}
          icon={<IcomoonIcon icon="ads" className={styles.icon} />}
          content={content}
        />
    );
};

AdCampaignFilterSummary.propTypes = {
    state: PropTypes.oneOf(['fullyApplied', 'partiallyApplied', 'notApplied']).isRequired,
    input: customPropTypes.postTagFilter.isRequired,
    hasCustomBinding: PropTypes.bool
};

AdCampaignFilterSummary.defaultProps = {
    hasCustomBinding: false
};

export default AdCampaignFilterSummary;
