import React, { memo } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PostKPI from 'src/components/views/postTags/PostKPI';
import styles from 'src/stylesheets/views/postTags/postKPIs.scss';

const PostKPIs = (props) => {
    const { postKpis } = props;
    return (
        <div className={styles.wrapper}>
            {
                postKpis.map((postKpi) => (
                    <div className={styles.kpi} key={postKpi.id}>
                        <PostKPI postKpi={postKpi} />
                    </div>
                ))
            }
        </div>
    );
};

PostKPIs.propTypes = {
    postKpis: customPropTypes.postKpis.isRequired
};

export default memo(PostKPIs);
