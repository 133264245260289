/* eslint-disable react/button-has-type */
import blueLoadingIndicator20px from 'src/resources/loading-indicators/circle-blue-20px.svg';
import blueLoadingIndicator24px from 'src/resources/loading-indicators/circle-blue-24px.svg';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import redLoadingIndicator20px from 'src/resources/loading-indicators/circle-red-20px.svg';
import redLoadingIndicator24px from 'src/resources/loading-indicators/circle-red-24px.svg';
import styles from 'src/stylesheets/button.scss';
import whiteLoadingIndicator20px from 'src/resources/loading-indicators/circle-white-20px.svg';
import whiteLoadingIndicator24px from 'src/resources/loading-indicators/circle-white-24px.svg';

const Button = forwardRef((props, ref) => {
    const {
        small,
        stretch,
        action,
        multiAction,
        warning,
        active,
        reminder,
        onClick,
        onMouseOver,
        onMouseOut,
        onFocus,
        onBlur,
        disabled,
        label,
        icon,
        iconImage,
        type,
        loading,
        className,
        feedbackWarning,
        favorite,
        responsive,
        leftCurved,
        rightCurved,
        alignLabel
    } = props;

    const baseClass = action === true ? styles.action : styles.default;
    let loadingIndicatorImage = null;
    if (small) {
        if (action) {
            loadingIndicatorImage = whiteLoadingIndicator20px;
        } else if (warning) {
            loadingIndicatorImage = redLoadingIndicator20px;
        } else {
            loadingIndicatorImage = blueLoadingIndicator20px;
        }
    } else if (action) {
        loadingIndicatorImage = whiteLoadingIndicator24px;
    } else if (warning) {
        loadingIndicatorImage = redLoadingIndicator24px;
    } else {
        loadingIndicatorImage = blueLoadingIndicator24px;
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button
          className={classnames(
              styles.button,
              baseClass,
              className,
              { [styles.small]: small },
              { [styles.rightCurved]: rightCurved },
              { [styles.leftCurved]: leftCurved },
              { [styles.large]: !small },
              { [styles.action]: action },
              { [styles.stretch]: stretch },
              { [styles.multiAction]: multiAction },
              { [styles.warning]: warning },
              { [styles.reminder]: reminder },
              { [styles.active]: active },
              { [styles.disabled]: disabled && !loading }, // loading state always wins over disabled state
              { [styles.loading]: loading },
              { [styles.iconOnly]: icon && !label },
              { [styles.feedbackWarning]: feedbackWarning },
              { [styles.favorite]: favorite },
              { [styles[alignLabel]]: true }
          )}
          ref={ref}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled || loading}
          type={type}
        >
            {
                loading
                && <img alt="Loading..." src={loadingIndicatorImage} className={styles.loadingIndicator} />
            }
            {
                icon
                && (
                    <div className={styles.iconWrapper}>
                        {
                            typeof icon === 'string'
                            && <IcomoonIcon icon={icon} className={styles.icon} />
                        }
                        {
                            typeof icon !== 'string'
                            && icon
                        }
                    </div>
                )
            }
            {
                iconImage
                && <img alt="icon" src={iconImage} className={styles.iconImage} />
            }
            {
                label
                && (
                <div className={classnames(styles.label, { [styles.responsive]: responsive })}>
                    {label}
                </div>
                )
            }
            {
                multiAction
                    && (
                    <div className={classnames(styles.multiActionIconWrapper, { [styles.multiActionDisabled]: disabled })}>
                        <div className={styles.multiActionSpacer} />
                        <IcomoonIcon icon="chevron-big-down" className={styles.multiActionIcon} />
                    </div>
                    )
            }
        </button>
    );
});

Button.propTypes = {
    small: PropTypes.bool,
    action: PropTypes.bool,
    multiAction: PropTypes.bool,
    warning: PropTypes.bool,
    reminder: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    iconImage: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    loading: PropTypes.bool,
    className: PropTypes.string,
    stretch: PropTypes.bool,
    feedbackWarning: PropTypes.bool,
    favorite: PropTypes.bool,
    responsive: PropTypes.bool,
    leftCurved: PropTypes.bool,
    rightCurved: PropTypes.bool,
    alignLabel: PropTypes.oneOf(['right', 'left', 'center']),
};

Button.defaultProps = {
    small: false,
    action: false,
    multiAction: false,
    warning: false,
    reminder: false,
    label: false,
    onClick: null,
    onMouseOver: null,
    onMouseOut: null,
    onFocus: null,
    onBlur: null,
    disabled: false,
    active: false,
    icon: null,
    iconImage: null,
    type: 'button',
    loading: false,
    className: '',
    stretch: false,
    feedbackWarning: false,
    favorite: false,
    responsive: false,
    leftCurved: false,
    rightCurved: false,
    alignLabel: 'center'
};

export default Button;
