import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withHighcharts = (type, HighchartsEngine) => {
    class HighchartsComponent extends Component {
        constructor(props) {
            super(props);
            this.setChartRef = this.setChartRef.bind(this);
            this.renderChart = this.renderChart.bind(this);
            this.getChart = this.getChart.bind(this);
        }

        componentDidMount() {
            const { config } = this.props;
            this.renderChart(config);
        }

        shouldComponentUpdate(nextProps) {
            const { config } = this.props;
            if (nextProps.isPureConfig && config === nextProps.config) {
                return true;
            }
            this.renderChart(nextProps.config);
            return false;
        }

        componentWillUnmount() {
            this.chart.destroy();
        }

        getChart() {
            if (!this.chart) {
                throw new Error('getChart() should not be called before the component is mounted.');
            }
            return this.chart;
        }

        setChartRef(chartRef) {
            this.chartRef = chartRef;
        }

        renderChart(config) {
            if (!config) {
                throw new Error('Config must be specified for the component.');
            }
            const { callback } = this.props;
            const chartConfig = Object.assign({}, config.chart, { renderTo: this.chartRef });
            const configWithRenderTarget = Object.assign({}, config, { chart: chartConfig });
            this.chart = new HighchartsEngine[type](configWithRenderTarget, callback);
        }

        render() {
            const { domProps } = this.props;
            return <div ref={this.setChartRef} {...domProps} />;
        }
    }

    HighchartsComponent.propTypes = {
        config: PropTypes.object.isRequired,
        isPureConfig: PropTypes.bool,
        callback: PropTypes.func,
        domProps: PropTypes.object
    };

    HighchartsComponent.defaultProps = {
        callback: () => {
        },
        domProps: {},
        isPureConfig: true
    };

    return HighchartsComponent;
};

export default withHighcharts;
