import * as customPropTypes from 'src/customPropTypes';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/selectorLabels.scss';
import ProfileSelectorLabelSingleSelection from 'src/components/profileSelector/ProfileSelectorLabelSingleSelection';

const ProfileSelectorLabel = (props) => {
    const { selectedProfilesOrGroups, disabled } = props;
    const { groupIds, profileIds, adAccountIds } = selectedProfilesOrGroups;
    const combinedLength = (groupIds ? groupIds.length : 0) + (profileIds ? profileIds.length : 0) + (adAccountIds ? adAccountIds.length : 0);
    if (combinedLength === 1) {
        return (
            <ProfileSelectorLabelSingleSelection
              disabled={disabled}
              groupId={groupIds[0]}
              profileId={profileIds[0]}
              adAccountId={adAccountIds[0]}
            />
        );
    } if (combinedLength > 1) {
        const selectionTexts = [];

        if (groupIds && groupIds.length > 0) {
            selectionTexts.push(`${groupIds.length} group${groupIds.length !== 1 ? 's' : ''}`);
        }

        if (profileIds && profileIds.length > 0) {
            selectionTexts.push(`${profileIds.length} profile${profileIds.length !== 1 ? 's' : ''}`);
        }

        if (adAccountIds && adAccountIds.length > 0) {
            selectionTexts.push(`${adAccountIds.length} ad account${adAccountIds.length !== 1 ? 's' : ''}`);
        }

        return (
            <div className={styles.wrapper}>
                <DivWithTooltip
                  onClick={() => {}}
                  tooltip="Multiple selections"
                  referenceWrapperClassName={styles.referenceWrapper}
                  className={styles.divWithEllipsis}
                >
                    {selectionTexts.join(' and ')}
                </DivWithTooltip>
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            <DivWithTooltip
              onClick={() => {}}
              tooltip="No profile selected"
              referenceWrapperClassName={styles.referenceWrapper}
              className={styles.divWithEllipsis}
            >
                No profile selected
            </DivWithTooltip>
        </div>
    );
};

ProfileSelectorLabel.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    disabled: PropTypes.bool,
};

ProfileSelectorLabel.defaultProps = {
    disabled: false
};

export default ProfileSelectorLabel;
