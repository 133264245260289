import NetworkIcon from 'src/components/NetworkIcon';
import { useSelector } from 'react-redux';
import { selectPlatformTypeByProfileId } from 'src/selectors/profiles';
import React from 'react';
import PropTypes from 'prop-types';

const ProfileNetworkIcon = ({ disabled, profileId, className }) => {
    const platformType = useSelector((state) => selectPlatformTypeByProfileId(state, profileId));
    return <NetworkIcon platformType={platformType} disabled={disabled} className={className} />;
};

ProfileNetworkIcon.propTypes = {
    profileId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

ProfileNetworkIcon.defaultProps = {
    disabled: false,
    className: ''
};

export default ProfileNetworkIcon;
