import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';

const Cell = (props) => {
    const {
        headerCell,
        className,
        children,
        onClick,
        width
    } = props;

    if (headerCell) {
        const style = {};

        if (width) {
            style.width = width;
        }
        return (
            <th onClick={onClick} style={style} className={className}>{children}</th>
        );
    }

    return <td className={className}>{children}</td>;
};

Cell.propTypes = {
    headerCell: PropTypes.bool,
    className: PropTypes.string,
    children: customPropTypes.children.isRequired,
    onClick: PropTypes.func,
    width: PropTypes.number
};

Cell.defaultProps = {
    headerCell: false,
    className: '',
};

export default Cell;
