import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAccountOptions } from 'src/selectors/loggedInUser';

const addThousandSeparators = (value, thousandSeparator) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

export const number = (value, numberFormat, decimals = 0) => {
    const intPart = parseInt(value, 10);
    const { thousandSeparator, decimalSeparator } = numberFormat;

    if (!window.Number.isNaN(intPart)) {
        const intPartWithThousandSeparator = addThousandSeparators(intPart, thousandSeparator);

        if (decimals === 0) {
            return intPartWithThousandSeparator;
        }

        const decimalPart = (Math.abs(value) % 1).toFixed(decimals).substring(2);

        return `${intPartWithThousandSeparator}${decimalSeparator}${decimalPart}`;
    }
    return 'n/a';
};

const Number = (props) => {
    const { value, decimals, accountOptions } = props;
    return <span>{number(value, accountOptions.numberFormat, decimals)}</span>;
};

Number.propTypes = {
    value: PropTypes.number,
    decimals: PropTypes.number,
    accountOptions: customPropTypes.accountOptions.isRequired
};

Number.defaultProps = {
    decimals: 0
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(Number);
