import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/filter.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PostTextValueList from './PostTextValueList';
import FilterItem from './FilterItem';

const CombinedPostTextFilterSummary = (props) => {
    const {
        state,
        input,
        hasCustomBinding
    } = props;

    return (
        <FilterItem
          state={state}
          hasCustomBinding={hasCustomBinding}
          icon={<IcomoonIcon icon="search" className={styles.icon} />}
          content={<PostTextValueList input={input} />}
        />
    );
};

CombinedPostTextFilterSummary.propTypes = {
    state: PropTypes.oneOf(['fullyApplied', 'partiallyApplied', 'notApplied']),
    input: customPropTypes.postTextIncludeAndExcludeFilter.isRequired,
    hasCustomBinding: PropTypes.bool
};

CombinedPostTextFilterSummary.defaultProps = {
    hasCustomBinding: false
};

export default CombinedPostTextFilterSummary;
