export function convertSecondsForView(sec) {
    if (sec < 60) {
        return '< 1 min';
    }

    if (sec < 3600) {
        const mins = Math.floor(sec / 60);
        return mins + ((mins === 1) ? ' min' : ' mins');
    }

    if (sec < 86400) {
        const hours = Math.floor(sec / 3600);
        return hours + ((hours === 1) ? ' hour' : ' hours');
    }
    if (sec < 604800) {
        const days = Math.floor(sec / 86400);
        return days + ((days === 1) ? ' day' : ' days');
    }

    return '> 1 week';
}

export function getClassNameForNumberFormatting(value) {
    if (value === null || window.Number.isNaN(value)) {
        return 'notAvailable';
    }
    if (value >= 0) {
        return 'green';
    }
    return 'red';
}
