import * as customPropTypes from 'src/customPropTypes';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/feedback.scss';

const FeedbackLinkPlain = (props) => {
    const {
        type, children, disabled, className
    } = props;
    const linkProps = _omit(props, ['type', 'disabled', 'children', 'className']);

    return (
        <a className={classnames(styles.link, styles[type], { [styles.disabled]: disabled, [className]: !!className })} {...linkProps}>
            {children}
        </a>
    );
};

FeedbackLinkPlain.propTypes = {
    type: customPropTypes.feedbackTypes,
    children: customPropTypes.children.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

FeedbackLinkPlain.defaultProps = {
    disabled: false,
    type: 'info'
};

export default FeedbackLinkPlain;
