import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import PureBodyWrapper from 'src/components/chartViews/fixedDataTable/PureBodyWrapper';
import styles from 'src/stylesheets/table/table.scss';

class TableBody extends PureComponent {
    constructor(props) {
        super(props);
        this.detectVerticalScroll = this.detectVerticalScroll.bind(this);
    }

    componentDidMount() {
        this.detectVerticalScroll();
    }

    componentDidUpdate(prevProps) {
        const {
            body, height, outerWidth
        } = this.props;
        // when inner body changes check for scroll
        if (body !== prevProps.body || prevProps.height !== height || prevProps.outerWidth !== outerWidth) {
            this.detectVerticalScroll();
        }
    }

    detectVerticalScroll() {
        const { outerWidth, setVerticalScrollbarPx } = this.props;
        const { scrollHeight, clientHeight, clientWidth } = this.scrollref;
        const hasScrollbar = scrollHeight > clientHeight;
        if (hasScrollbar) {
            const scrollBarWidth = outerWidth - clientWidth;
            // we give it a little extra space.
            // In windows it still shows 1px scrollbars sometimes, caused by rounding issues or smth so we add a bit extra
            const ceiledWidth = Math.ceil(scrollBarWidth * 1.05);
            setVerticalScrollbarPx(ceiledWidth);
        } else {
            setVerticalScrollbarPx(0);
        }
    }

    render() {
        const {
            height,
            onScroll,
            refCallback,
            header,
            body,
            hasSummaryLine
        } = this.props;
        const headerHeight = 43;
        const summaryLineHeight = 43;
        let renderHeight = height - headerHeight;
        if (hasSummaryLine) {
            renderHeight -= summaryLineHeight;
        }
        const ref = (c) => { refCallback(c); this.scrollref = c; };
        return (
            <div
              ref={ref}
              className={classnames(styles.tableBody)}
              style={{ height: renderHeight }}
              onScroll={onScroll}
            >
                <table
                  className={styles.table}
                  style={{ marginTop: -headerHeight }}
                >
                    {header}
                    <PureBodyWrapper body={body} />
                </table>
            </div>
        );
    }
}

TableBody.propTypes = {
    header: PropTypes.any.isRequired,
    body: PropTypes.any.isRequired,
    height: PropTypes.number.isRequired,
    onScroll: PropTypes.func,
    refCallback: PropTypes.func.isRequired,
    hasSummaryLine: PropTypes.bool.isRequired,
    setVerticalScrollbarPx: PropTypes.func.isRequired,
    outerWidth: PropTypes.number.isRequired,
};

export default TableBody;
