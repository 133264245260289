import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/table/paginationTableFooter.scss';

const StaticTableFooter = (props) => {
    const {
        height, totalResultsCount
    } = props;

    const entryWording = totalResultsCount === 1 ? 'row' : 'rows';

    return (
        <div className={styles.footer} style={{ height }}>
            <div className={styles.info}>
                Showing {totalResultsCount} {entryWording}
            </div>
        </div>
    );
};

StaticTableFooter.propTypes = {
    height: PropTypes.number,
    totalResultsCount: PropTypes.number
};

export default StaticTableFooter;
