import { calculateSortIndexes, findColumnById, PAGE_LENGTH } from 'src/components/chartViews/fixedDataTable/utils';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SortedResultsWrapper from 'src/components/chartViews/fixedDataTable/SortedResultsWrapper';
import Table from './tableEngine/Table';

class ClientSideTable extends Component {
    constructor(props) {
        super(props);
        const { results, tableConfig } = props;

        const sortedByColumn = findColumnById(tableConfig.sortBy, tableConfig.columns);

        this.state = {
            onPage: 0,
            sortedResults: new SortedResultsWrapper(calculateSortIndexes(results, sortedByColumn, tableConfig.sortDir), results),
            columnsSortBy: tableConfig.sortBy,
            columnsSortDir: tableConfig.sortDir
        };
        this.onPaginationClick = this.onPaginationClick.bind(this);
        this.onSortChangeClick = this.onSortChangeClick.bind(this);
    }

    onPaginationClick(page) {
        this.setState({
            onPage: page
        });
    }

    onSortChangeClick(column, sortDir) {
        const { results } = this.props;
        this.setState({
            sortedResults: new SortedResultsWrapper(calculateSortIndexes(results, column, sortDir), results),
            columnsSortBy: column.id,
            columnsSortDir: sortDir,
            onPage: 0
        });
    }

    render() {
        const { tableConfig, settings, paginate } = this.props;
        const { sortedResults, columnsSortBy, columnsSortDir } = this.state;
        const { columns } = tableConfig;

        const { onPage } = this.state;
        const pageLength = PAGE_LENGTH;
        const start = onPage * pageLength;
        const limit = _get(settings, 'settingAdditional.limit', 0);
        let results = limit > 0 ? sortedResults.getSlice(0, limit) : sortedResults;
        const totalResultsCount = results.getSize();
        if (paginate) {
            results = results.getSlice(start, start + pageLength);
        }

        const hideColumns = _get(settings, 'settingAdditional.hideColumns', tableConfig.hideColumns);
        const rowsCount = results.getSize();
        const fixedDataTableProps = _omit(this.props, ['results', 'settings', 'tableConfig']);

        return (
            <Table
              columns={columns}
              hiddenColumns={hideColumns}
              columnsSortBy={columnsSortBy}
              columnsSortDir={columnsSortDir}
              results={results}
              paginate={paginate}
              tableConfig={tableConfig}
              onPage={onPage}
              totalResultsCount={totalResultsCount}
              pageLength={pageLength}
              onSortChangeClick={this.onSortChangeClick}
              onPaginationClick={this.onPaginationClick}
              rowsCount={rowsCount}
              {...fixedDataTableProps}
            />
        );
    }
}

ClientSideTable.propTypes = {
    tableConfig: customPropTypes.tableConfig.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    settings: customPropTypes.dashboardMetricSettings,
    paginate: PropTypes.bool
};

ClientSideTable.defaultProps = {
    paginate: false
};

export default connect(null, {})(ClientSideTable);
