import {
    adAccountForceDeleteSuccess,
    adAccountDeleteSuccess,
    adAccountBulkAddSuccess,
    adAccountBulkAddRequest,
    adAccountBulkAddError,
    adAccountAddRequest,
    adAccountAddSuccess,
    adAccountAddError,
    adAccountAddCleanup, adAccountCustomizeSuccess
} from 'src/actions/adAccounts';
import { combineReducers } from 'redux';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates, createAsyncStatesReducerForAction } from 'src/reducers/utils';
import { GET_AUTH_TRANSACTION_BY_HASH_SUCCESS } from 'src/actions/authTransactions';

const byId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case PREPARE_EXPORT_DATA:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case adAccountBulkAddSuccess.type:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS: {
            const { adAccounts } = payload;
            return Object.assign({}, state, adAccounts);
        }
        case adAccountForceDeleteSuccess.type:
        case adAccountDeleteSuccess.type:
        {
            const { accountIds } = payload;
            return _omit(state, accountIds);
        }
        case adAccountCustomizeSuccess.type: {
            const { adAccount } = payload;
            return Object.assign({}, state, { [adAccount.id]: adAccount });
        }
        default:
            return state;
    }
};

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case PREPARE_EXPORT_DATA:
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS:
        case USER_LOGGED_IN:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS: {
            const { adAccounts } = payload;
            return Object.keys(adAccounts);
        }
        case adAccountBulkAddSuccess.type: {
            const { adAccounts } = payload;
            return _uniq([...state, ...Object.keys(adAccounts)]);
        }
        case adAccountForceDeleteSuccess.type:
        case adAccountDeleteSuccess.type:
        {
            const { adAccountIds } = payload;
            return _difference(state, adAccountIds);
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    adding: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.adAccountId,
        [adAccountAddRequest.type],
        [adAccountAddSuccess.type],
        [adAccountAddError.type],
        [adAccountAddCleanup.type]
    ),
    bulkAdding: createAsyncStatesReducerForAction(
        () => 'state',
        [adAccountBulkAddRequest.type],
        [
            adAccountBulkAddSuccess.type,
            adAccountBulkAddError.type
        ]
    ),
});

export const adAccounts = combineReducers({
    allIds,
    byId,
    asyncStates
});
