import * as customPropTypes from 'src/customPropTypes';
import Cell from 'src/components/chartViews/fixedDataTable/tableEngine/Cell';
import PropTypes from 'prop-types';
import React from 'react';
import { integer } from 'src/components/chartViews/renderers';
import styles from 'src/stylesheets/table/tableCell.scss';
import { getClassNameForNumberFormatting } from 'src/components/chartViews/fixedDataTable/tableCellRenderers/utils';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { connect } from 'react-redux';

const Integer = (props) => {
    const { value, cellProps, accountOptions } = props;
    const parsedValue = parseInt(value, 10);
    let colorClassName = 'notAvailable';
    if (parsedValue !== null && !window.Number.isNaN(parsedValue)) {
        colorClassName = getClassNameForNumberFormatting(parsedValue);
    }
    return (
        <Cell {...cellProps}>
            <span className={styles[colorClassName]}>
                {integer(value, accountOptions.numberFormat)}
            </span>
        </Cell>
    );
};

Integer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cellProps: customPropTypes.fixedDataTableCellProps.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

Integer.defaultProps = {
    value: null
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(Integer);
